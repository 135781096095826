button.slick-arrow.slick-prev:not(.show-item),
button.slick-arrow.slick-next:not(.show-item) {
  display: none !important;
}

button.slick-arrow.slick-prev.show-item,
button.slick-arrow.slick-next.show-item {
  svg {
    width: 25px;
    height: 30px;
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(62deg) brightness(102%) contrast(102%);
  }
}

button.slick-arrow.slick-prev.show-item {
  position: absolute;
  top: 50%;
  z-index: 99;
  left: 11px;
}

button.slick-arrow.slick-next.show-item {
  position: absolute;
  top: 50%;
  right: 11px;
  z-index: 1;
}

.home {
  padding-bottom: 120px;

  &__banner {
    @media (min-width: 992px) {
      display: none;
    }

    .home__box {
      height: 130px;
      margin-top: -50px;
      z-index: 2;

      .box__footer {
        justify-content: inherit;
        display: flex;
        padding-top: 5px;

        p {
          margin: 0;

          span {
            font-weight: 600;
            color: #1890FF;
          }
        }

        .icon {
          margin-right: 10px;
        }
      }

      p.tip {
        font-weight: 600;
      }

      .btn__form {
        width: 100%;
        text-align: center;
      }
    }

    #box__banner {
      height: 100%;
      border-radius: 5px;
      overflow: hidden;

      .banner__home__content {
        height: 100%;
        position: relative;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__marketing {
    .container {
      padding-right: 0;
    }
  }

  &__marketing__content {
    padding-bottom: 15px;
    margin-top: 15px;

    .item__marketing {
      border-radius: 4px;

      img {
        border-radius: 4px;
      }
    }
  }

  &__super_discount {
    background: #FFE7BA;
    padding: 15px 0;

    .time__discount {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;

      .super__discount {
        display: flex;
        align-items: center;

        p {
          margin-right: 10px;
          font-weight: 600;
          margin-bottom: 0;
        }
      }
    }
  }

  &__superDiscount__content {
    .superDiscount__item {
      position: relative;
      text-align: center;
      padding-right: 8px;

      &__content {
        background: #fff;
        padding-bottom: 8px;
        border-radius: 4px;

        .item__image {
          padding-top: 15px;
        }

        img {
          margin: auto;
          width: 104px;
          height: 112px;
          object-fit: cover;
        }
      }

      .price {
        font-size: 14px;
        font-weight: 600;
      }

      p {
        font-size: 12px;
      }

      p.chiet-khau {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .discount {
        position: absolute;
        top: 0;
        left: 0;
        background: #F5222D;
        font-size: 12px;
        color: #fff;
        border-radius: 4px 0;
        padding: 1px 2px;
      }
    }

    .slick-track {
      @media (max-width: 767px) {
        left: 0 !important;
      }
    }
  }

  &__product__content {
    padding-top: 15px;

    .header__title {
      padding-bottom: 15px;
      border-bottom: 1px solid #E8E8E8;
    }

    h2 {
      font-size: 16px;
      font-weight: 600;
    }

    .home__product {
      .item__product {
        .item__image {
          width: 100%;
          text-align: center;

          img {
            width: 115px;
            object-fit: contain;
            margin-bottom: 10px;
            height: 115px;
            border-radius: 5px;
          }
        }
      }
    }
  }

  &__super_discount__all {
    .container {
      padding: 12px;
    }

    .home__superDiscountAll__content {
      display: flex;
      flex-wrap: wrap;

      .item__discount {
        position: relative;
        border-radius: 4px;
        background: #fff;
        margin: 4px;
        width: 100%;
        height: 104px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 4px;
        }

        .chiet-khau {
          padding: 0 6px;
          border: 1px solid #1890FF;
          background: #FFFFFF;
          border-radius: 4px;
          width: 80px;
          position: absolute;
          bottom: 0;
          right: 8px;
          text-align: center;

          span {
            display: inline-block;
            margin-left: 5px;
            font-size: 12px;
            font-weight: 500;
          }
        }

        .discount {
          position: absolute;
          top: 0;
          left: 0;
          background: #F5222D;
          font-size: 12px;
          color: #fff;
          border-radius: 4px 0;
          padding: 1px 2px;
        }
      }

      .item__discount__big {
        width: calc(100% - 100% / 3 - 8px);
        height: 220px;
      }

      .item__discount__small {
        width: calc(100% / 3 - 8px);
        //height: 106px;
      }
    }

    background: linear-gradient(180deg, #1890FF 0%, #096DD9 100%),
    #096DD9;
  }

  .box-loading {
    height: 70px;

    .ic-sm {
      width: 50px;
    }
  }

  &__chanel {
    &__content {
      background: #FFFFFF;
      box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1);
      padding: 10px;
      margin-bottom: 20px;
      text-align: center;

      p {
        font-size: 14px;
        color: #262626;
      }

      h4 {
        color: #262626;
        font-weight: 600;
        margin-bottom: 10px;
      }
    }
  }

  .home__product__item {
    padding: 15px 0;
    &.home-top-5 {
      .list-top5 {
        display: flex;
        overflow-x: scroll;
        justify-content: flex-start;
        margin-left: 16px;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        &::-webkit-scrollbar {
          display: none;  /* Safari and Chrome */
        }
        .itemCard {
          flex-direction: column;
          .text-group {
            .title {
              padding-left: 0;
              margin-top: 8px;
            }
            .comm {
              display: flex;
              gap: 3px;
            }
          }
        }
      }
      .title-hot-product {
        margin: 0;
        box-shadow: none;
        .title {
          margin-bottom: 0;
        }
      }
    }
    h2.title {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 15px;
    }

    border-bottom: 4px solid #F5F5F5;
  }


  .hot__product {
    padding-bottom: 15px;
    border-bottom: 4px solid #F5F5F5;

    h2.title {
      font-size: 16px;
      font-weight: 600;
      color: #262626;
      text-transform: uppercase;
      text-align: center;
    }

    &__item {
      display: flex;
      align-items: center;
      padding: 10px 0;
      border-bottom: 1px solid #F5F5F5;

      span.stt {
        font-size: 16px;
        font-weight: 600;
        color: #000000;
        padding: 0 15px;
      }

      .images {
        width: 56px;
        height: 56px;
        border: 1px solid #E8E8E8;
        border-radius: 4px;
        margin-right: 8px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .text {
        width: calc(100% - 98px);

        p.title {
          font-size: 14px;
          font-weight: 600;
        }

        p.total__order {
          span {
            font-weight: 600;
          }
        }
      }
    }
  }

  &__mobile {

    .home__deal__hot {
      padding: 15px;

      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-transform: uppercase;
        font-weight: bold;

        p {
          width: calc(100% - 20px)
        }

        a {
          font-weight: 600;
          font-size: 16px;
        }
      }

      .list {
        display: flex;
        flex-wrap: wrap;

        .deal__item__right {
          float: left;
          max-width: calc(50% - 7px);
          margin-left: 7px;

          &>.deal__item__row {
            .deal__item {
              display: block;
              margin-top: 15px;

              img {
                width: 100%;
                border-radius: 4px;
              }

              &:first-child {
                margin-top: 0;
              }
            }
          }
        }

        .deal__item__right__more {
          display: flex;
          flex-wrap: wrap;
          clear: both;

          .deal__item__left {
            padding-top: 15px;

            &:nth-child(even) {
              margin-right: 0;
              margin-left: 7px;
            }

            img {
              width: 100%;
              height: unset;
              border: 1px solid #DFDFDF;
              border-radius: 8px;
              overflow: hidden;
            }
          }
        }

        .deal__item__left {
          flex: 0 0 50%;
          max-width: calc(50% - 7px);
          margin-right: 7px;

          img {
            width: 100%;
            height: 100%;
            border-radius: 4px;
          }
        }
      }
    }

    @media (min-width: 992px) {
      display: none;
    }
  }

  &__desktop {
    @media (max-width: 768px) {
      display: none;
    }

    .search__product__desktop {
      position: relative;
    }

    &__content {
      display: flex;
    }

    .home__left {
      width: calc(67% - 15px);
      margin-right: 15px;

      .home__category {
        .home__list__options {
          .list__options {
            justify-content: inherit;
          }

          .option__item {
            width: calc(100% / 6);

            @media (max-width: 991px) {
              width: calc(100% / 4);
            }
          }
        }
      }
    }

    .home__right {
      width: 33%;

      .home__box {
        background: #ffffff;
        padding: 15px;
        border-radius: 5px;
        margin-bottom: 15px;

        .box__content {
          border-bottom: 1px solid #d9d9d9;

          .box__commission {
            justify-content: space-between;
            display: flex;

            .total__commission {
              color: #262626;
              font-size: 16px;
              text-transform: uppercase;
              margin-bottom: 0;
            }
          }

          .total__price {
            font-size: 28px;
            font-weight: 600;
          }
        }

        .box__footer {
          display: flex;
          padding-top: 5px;

          p {
            margin: 0;

            span {
              font-weight: 600;
              color: #1890FF;
            }
          }

          .icon {
            margin-right: 10px;
          }

        }

        .btn__form {
          width: 100%;
          text-align: center;
          padding-top: 10px;
        }
      }

      .hot__product {
        background: #ffffff;
        border-radius: 5px;
        padding-bottom: 0;
        padding-top: 12px;

        h2.title {
          text-align: left;
          padding-left: 15px;
          text-transform: none;
        }
      }
    }

    .home__banner__desktop {
      width: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 5px;
        aspect-ratio: 3/1;

      }

      margin-bottom: 30px;
    }

    .home__banner__desktop .item__banner__slider>a {

      //padding-right: 16px;
      img {
        height: 250px;
        border-radius: 8px;
      }
    }

    .home__share {
      background: #ffffff;
      padding: 15px;

      .list__share__near {
        display: flex;
        flex-wrap: wrap;

        .hot__week__item {
          width: calc(25% - 10px);
          height: auto;
        }
      }

      .btn__group {
        text-align: center;
      }

      .btn_choose_blue {
        width: 200px;
        margin: 0 auto;
      }
    }

    .home__deal__hot {
      background: white;
      padding: 15px;
      margin: 15px 0 10px;

      .title {
        display: flex;
        justify-content: space-between;

        p:first-child {
          text-transform: uppercase;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
        }

        a {
          font-weight: 600;
          font-size: 16px;
        }
      }

      .list {
        display: flex;
        flex-wrap: wrap;

        .deal__item__left {
          flex: 0 0 25%;
          max-width: 25%;
          height: 310px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .deal__item__right {
          flex: 0 0 75%;

          .deal__item__row {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-left: 10px;
          }

          .deal__item {
            box-sizing: border-box;
            margin: 0 10px 0 0;
            width: calc(1 / 3 * 100% - (1 - 1 / 3) * 10px);
            max-height: 150px;
            border: 1px solid #DFDFDF;
            border-radius: 8px;
            overflow: hidden;

            img {
              width: 100%;
              max-height: 100%;
              border-radius: 4px;
            }

            &:nth-child(3n) {
              margin-right: 0;
            }

            &::after {
              content: '';
              flex: auto;
            }

            &:nth-child(-n+3) {
              margin-top: 0;
            }

            &.mt-2 {
              margin-top: 10px;
            }
          }
        }
      }
    }

    .home__product {
      .item__product {
        width: calc(25% - 10px);
        border: none;

        @media (min-width: 1200px) {
          width: calc(25% - 10px);
        }

        img {
          width: 115px;
          object-fit: contain;
          margin-bottom: 10px;
          height: 115px;
          border-radius: 5px;
        }
      }

      .btn_choose_blue {
        width: 200px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .home__suggestion {
      background: #ffffff;
      padding: 15px;

      .btn_choose_blue {
        width: 200px;
        margin: 0 auto;
      }
    }

    .home__desktop__item {
      margin: 15px 0;

      .title {
        h2 {
          font-size: 16px;
          font-weight: 600;
          text-transform: uppercase;
          margin-bottom: 15px;
        }
      }
    }
  }

  &.is__buyer {
    .home__marketing__content {
      padding-top: 15px;
    }
  }

  &__banner__bottom {
    &__content {
      height: 140px;
      margin: 15px 0;
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 5px;
    }
  }

  .home__coupon {
        background: #ffffff;
        
        &__title {
            padding: 15px;
            display: flex;
            justify-content: space-between;

            a {
                color: rgb(61, 63, 64);
                font-size: 16px;
                font-weight: 600;
            }
        }

        &__list {
            padding: 15px;
            display: grid;
            grid-template-columns: repeat(3,310px);
            gap: 15px;
            
        }
        .scroll-coupon {
            overflow-x: scroll;

            &::-webkit-scrollbar {
                width: 1px;
                height: 5px;
              }
              
              /* Track */
            &::-webkit-scrollbar-track {
                border-radius: 10px;
              }
               
              /* Handle */
            &::-webkit-scrollbar-thumb {
                background: grey; 
                border-radius: 10px;
              }
        }
         
        @media (max-width: 768px) {
            border-top: 2px solid #E5EBED;

            &__list {
                display: block;
                overflow: hidden;
                .coupon__item {
                    margin-bottom: 10px;
                }
            }
        }
    }
}

.home__product {
  @media (min-width: 991px) {
    .item__product {
      width: calc(25% - 10px);
      border: none;
    }
  }
}

.ant-notification-hook-holder,
.ant-notification-notice {
  width: 250px !important;
  border-top: 3px solid #1890FF;
  padding: 0;

}

.ant-notification.ant-notification-bottomRight {
  bottom: 60px !important;

}

.ant-notification-notice-close {
  top: 5px;
  right: 10px;
}

.noti__publisher {
  //position: fixed;
  //background: #ffffff;
  z-index: 11;
  //bottom:100px;
  transition: .4s;
  //transform: translateX(100%);
  //box-shadow: 1px 0 8px rgba(0, 0, 0, 0.15);
  padding: 0 12px 12px 12px;
  //width: 60%;
  min-height: 50px;
  right: 14px;

  .btn__close {
    margin-top: 5px;
    text-align: right;
  }

  &__content {
    display: flex;
  }

  .icon {
    margin-right: 10px;
  }

  p {
    font-size: 14px;

    span {
      color: #1890FF;
    }
  }
}

.page__wrapper {
  min-height: 100%;
  margin-left: 256px;
  transition: all .4s ease;

  &.collapsed {
    margin-left: 80px;
  }

  .page__body {
    padding: 30px;
    background: #f7f7f7;
    overflow: hidden;
  }

  &.not__sidebar {
    margin-left: 0;
  }

  @media (max-width: 991px) {
    margin-left: 0;
    padding-top: 0;

    &.collapsed {
      margin-left: 0px;
    }

    .page__body {
      padding: 0;
      background: #ffffff;
    }
  }
}

@media (min-width: 1200px) {
  .container {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .container {
    width: 100%;
  }
}


@media (max-width: 413px) {
  .home__marketing__content .slick-track {
    left: -50px !important;
  }

  .home__superDiscount__content .slick-track {
    left: -50px !important;
  }

  .list__hot__week .slick-track,
  .top__product .slick-track {
    left: 0px !important;
  }

  .rule__content .slick-track {
    left: 0px !important;
  }
}

@media (min-width: 414px) and (max-width: 767px) {
  .home__superDiscount__content .slick-track {
    left: -50px !important;
  }

  .home__marketing__content .slick-track {
    left: -50px !important;
  }

  .list__hot__week .slick-track,
  .top__product .slick-track {
    left: 5px !important;
  }

  .rule__content .slick-track {
    left: 0px !important;
  }
}

.error__product {
  min-height: 100px;
  text-align: center;
  width: 100%;

  img {
    width: 140px;
    height: 145px;
    object-fit: cover;
  }

  //box-shadow: 1px 0 8px rgba(0, 0, 0, 0.15);

  p {
    margin-top: 10px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.54);
  }
}


@media (max-width: 1339px) {
  .home__desktop .home__deal__hot .list .deal__item__left {
    height: unset;
  }

  .home__desktop .home__deal__hot .list {
    margin-bottom: -15px;
  }

  .home__desktop .home__deal__hot .list .deal__item__left img {
    height: calc(100% - 15px);
  }
}