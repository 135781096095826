.notification{
  transition: 0.4s;
  background: #ffffff;
  z-index: 1;
  padding-bottom: 80px;
  @media (min-width: 768px) {
    position: inherit;
    animation: none;
  }
  .nav__menu{
    display: flex;
    .nav__item{
      span{
        color: #262626;
      }
      width: 50%;
      cursor: pointer;
      text-align: center;
      border-bottom: 1px solid #D9D9D9;
      padding: 15px;
      &.active{
        span{
          color: #1890FF;
        }
        border-bottom: 2px solid #1890FF;
      }
    }
    button.active{
      border: 1px solid #1890FF;
      color: #1890FF;
    }
  }
  &__content{
    padding: 15px 0 ;
    @media (min-width: 768px) {
      display: flex;
      justify-content: space-between;
    }
  }
  &__title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 15px 0 ;
    h3{
      font-size: 14px;
      font-weight: 600;
    }
    .saw{
      font-size: 12px;
      color: #595959;
      svg{
        margin-right: 5px;
      }
    }
  }
  &__item {
    display: flex;
    padding: 15px 0;
    border-bottom: 1px solid #D9D9D9;
    .item__logo{
      width: 40px;
      height: 40px;
      margin-right: 10px;
      img{
        width: 100%;
        object-fit: cover;
      }
    }
    .item__content{
      width: calc(100% - 50px);
      .title{
        h4{
          font-size: 14px;
          font-weight: 400;
          width: calc(100% - 20px);
          .order__code{
            color: #1890FF;
          }
        }
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        margin-bottom: 10px;
        svg{
          display: none;
        }
      }
      .time{
        font-size: 12px;
        margin-bottom: 0px;
        color: #8C8C8C;
        font-style: italic;
      }
      .content{
        width: calc(100% - 20px);
        color: #595959;
        .price__commission{
          font-weight: 600;
        }
      }
      &.active{
        .title{
          h4{
            font-weight: 600;
          }
          svg{
            display: block;
          }
        }
      }
    }
  }
  &__detail{
    background: #ffffff;
    .banner{
      img{
        width: 100%;
        object-fit: cover;
      }
      @media (min-width: 991px) {
        text-align: center;
        display: none;
        img{
          width: 100%;
          height: 300px;
        }
      }
    }

    img{
      width: 100%;
      object-fit: cover;
    }
    @media (min-width: 991px) {
      img{
        width: 400px;
        object-fit: cover;
      }
    }
    &__content{
      padding: 30px 0;
      h2.title{
        font-size: 20px;
        font-weight: 600;
      }
    }
  }
}

@keyframes toPage {
  from { transform: translateX(-100%);}
  to {  transform: translateX(0);}
}

@keyframes hidePage {
  from { transform: translateX(0);}
  to {  transform: translateX(-100%);}
}
