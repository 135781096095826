span{
  display: inline-block;
}
p, span {
  font-size: 14px;
}
.background__blue{
  background: #1890FF!important;
}
p{
  color: #262626;
}
.ant-btn[disabled] {
  background: #1890FF !important;
  border-color: #1890FF !important;
  color: #fff !important;
  opacity: .5 !important;

}
.mt-10{
  margin-top: 10px!important;
}
.btn__orange {
  border: 1px solid #FFA145;
  color: #FFA145;
  border-radius: 4px;
}
.btn_orange, .btn_orange:hover {
  background: #FFA145;
  color: #fff;
  border: 1px solid #FFA145;
}
.btn_choose_blue{
  color: #1890FF;
  padding: 4px 20px ;
  border: 1px solid #1890FF;
  background: #ffffff;
  &:focus{
    background: #ffffff;
  }
  span{
    color: #1890FF!important;
  }
}

.box__header__title{
  background: #FA8C16;
  padding: 12px 15px;
  .box__header__title__content{
    display: flex;
    align-items: center;
    a{
      color: #fff;
      display: inline-block;
    }
    h2{
      font-weight: 400;
      font-size: 16px;
      color: #ffffff;
      width: calc(100% - 50px);
      text-align: center;
    }
  }
}
.header__bell{
  &.open{
    animation: vibrateCart 1s forwards;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  svg {
    width: 22px;
    height: 22px;
  }
}
@keyframes vibrateCart {
  0% {
    transform: rotate(0deg);
  }
  25% {transform: rotate(45deg);}
  50% {transform: rotate(0deg);}
  75% {transform: rotate(-45deg);}
  100% {transform: rotate(0deg);}
}

.border__bottom{
  border-bottom: 4px solid #F5F5F5;
}

.image-gallery{
  height: 360px;
  width: 100%;
  overflow: hidden;
}
.image-gallery-content {
  position: relative;
  line-height: 0;
  top: 0;
}

.image-gallery-slide-wrapper {
  height: calc(100%);
}
.image-gallery-left-nav, .image-gallery-right-nav {
  display: none;
}

.image-gallery-slide-wrapper {
  position: relative;
}

.image-gallery-thumbnails-wrapper {
  height: 95px;
  display: none;
}

.image-gallery-thumbnails-wrapper {
  position: relative;
}

.image-gallery-icon {
  filter: none;
}
.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg{
  height: 50px;
  width: 20px;
  stroke: #FA8C16;
}
.image-gallery, .image-gallery-content, .image-gallery-slide>div, .image-gallery-slides, .image-gallery-swipe{
  height: 340px;
}
.image-gallery-thumbnails {
  overflow: hidden;
  padding: 5px 0;
}
.image-gallery-thumbnails .image-gallery-thumbnails-container {
  cursor: pointer;
  text-align: center;
  transition: transform .45s ease-out;
  white-space: nowrap;
}
.ant-modal-wrap.modal-gallery .image-gallery-thumbnail {
  height: 69px;
  width: 69px;
  border-radius: 3px;
  border: 3px solid transparent;
}
.image-gallery-thumbnail {
  display: inline-block;
  border: 4px solid transparent;
  transition: border .3s ease-out;
  width: 69px;
  background: transparent;
  padding: 0;
}
.image-gallery-slide {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.image-gallery-image {
  text-align: center;
  height: 85%;
  width: 100%;
  object-fit: contain;
}
.image-gallery-thumbnail-inner {
  width: 67px;
  height: 71px;
}
.image-gallery-thumbnail-inner img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 3px;
}
.image-gallery-thumbnail.active{
  border: 1px solid #1890FF;
  border-radius: 4px;
}
.ant-pagination{
  text-align: center;
}
.ant-pagination-item{
  width: 25px;
  height: 25px;
  line-height: 24px;
}
.ant-pagination-prev, .ant-pagination-next, .ant-pagination-jump-prev, .ant-pagination-jump-next{
  width: 25px;
  min-width: 25px;
  height: 25px;
  line-height: 24px;

}
.ant-btn-primary{
  span{
    color: #ffffff;
    font-weight: 500;
  }
}
.ant-message{
  text-align: center;
  top: 15px;
}
button.outline__primary {
  border: 1px solid #096DD9;
  color: #096DD9;
}
.image-gallery{
  margin-top: 15px;
}
.image-gallery, .image-gallery-content, .image-gallery-slide > div, .image-gallery-slides, .image-gallery-swipe{
  height: 300px!important;
}



///// ảnh product detail modal
.modal__max__quantity {
  width: 320px!important;
  top: 10vw;
  .ant-modal-body {
    height: calc(100% - 55px);
    padding: 0 0 15px 0;
  }
  .ant-modal-content {
    height: 180px;
  }
}

.modal__image__product__detail {
  width: 60%!important;
  top: 1vw;
  .ant-modal-body {
    height: calc(100% - 55px);
    padding: 0 0 15px 0;
  }
  .ant-modal-content {
    height: 70vh;
  }
}
.desktop__image__product{
  height: 100%!important;

  .image-gallery .image-gallery-slide-wrapper{
    height: calc(100% - 95px);
    position: relative;
  }
  .image-gallery{
    height: 100% !important;
  }
  .image-gallery .image-gallery-content {
    height: 100%!important;
  }
  .image-gallery-thumbnails-wrapper{
    display: block;
  }
  .image-gallery .image-gallery-slide, .image-gallery .image-gallery-slide>div, .image-gallery .image-gallery-slides, .image-gallery .image-gallery-swipe {
    height: 100%!important;
  }
  .image-gallery-slides {
    line-height: 0;
    overflow: hidden;
    position: relative;
    white-space: nowrap;
    text-align: center;
  }
  .image-gallery-swipe, .image-gallery-slides{
    height: 100%!important;
  }
  .image-gallery-image {
    text-align: center;
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  .image-gallery-left-nav, .image-gallery-right-nav {
    padding: 50px 10px;
    top: 50%;
    transform: translateY(-50%);
    color: red;
    display: block;
    z-index: 1000;
    position: absolute;
  }
  .image-gallery-right-nav{
    right: 0;
  }
}
.text-center{
  text-align: center;
}
body{
  background: #f7f7f7;
  @media (max-width: 767px) {
    background: #ffffff;
  }
}
.pt-0{
  padding-top: 0;
}

.box__header__title{
  @media (min-width: 992px) {
    display: none;
  }
}

.not__found__page{
  &__content{
    background: #ffffff;
    padding: 100px 0;
    text-align: center;
    img{
      margin-bottom: 15px;
    }
    p.text{
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 15px;
    }
  }
}

.popup__delete{
  width: 80%!important;
  margin-top: 40%;
  @media (min-width: 992px) {
    margin-top: 10%;
    width: 500px!important;
  }
  .ant-modal-close{
    display: none;
  }
  .ant-modal-body{
    padding: 0;
    border-radius: 5px;
    overflow: hidden;
  }
  p.text{
    padding: 10px 15px;
  }
  .action__confirm{
    display: flex;
    span{
      padding: 15px;
      width: 50%;
      text-align: center;
      border-top: 1px solid #d9d9d9;
      border-right: 1px solid #d9d9d9;
      font-weight: 600;
      cursor: pointer;
      &.no{
        color: #F5222D ;
      }
      &.yes{
        color: #52C41A;
      }
    }
  }
}

.pagination {
  display: flex;
  justify-content: flex-end;
  padding: 15px 15px;
  &__item{
    &.disabled{
      cursor: not-allowed;
      .pagination__link{
        pointer-events: none;
        opacity: .4;
      }
    }
    &.active{
      .pagination__link {
        color: #1890ff;
        border-color: #1890ff;
      }
    }
  }
  .pagination__link {
    min-width: 24px;
    height: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #595959;
    border: 1px solid #d9d9d9;
    background: #fff;
    border-radius: 3px;
    transition: .3s;
    .icon{
      width: 24px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      svg{
        width:50%;
      }
    }
  }
  .pagination__item+.pagination__item {
    padding-left: 8px;
  }
}

.d2c__loading__component{
  padding: 30px 15px;
  background: #f7f7f7;
  text-align: center;
}
ul, li {
  list-style: none;
}

.btn__footer{
  position: fixed;
  bottom: 0;
  background: #ffffff;
  left: 0;
  right: 0;
  z-index: 10;
  box-shadow: 1px 0 8px rgba(0, 0, 0, 0.15);
  padding-top: 15px;
}


.d2c__policy{
  background: #ffffff;
  &__content{
    padding: 15px 0;
    h3{
      font-size: 16px;
      text-transform: uppercase;
      color: #262626;
      margin-bottom: 10px;
    }
    h2{
      font-size: 24px;
      text-transform: uppercase;
      color: #262626;
      margin-bottom: 10px;
    }
    ul {
      padding-left: 40px;

      li{
        list-style: disc;
        margin-bottom: 10px;
        color: #555;


      }
    }
    ol{
      padding-left: 40px;
      li{
        margin-bottom: 10px;
        list-style: decimal;
        color: #555;

      }
    }
    .info{
      p{
        padding-left: 30px;
        font-size: 15px;
      };
    }
    p.title {
      font-size: 15px;
      padding-left: 0px;
      color: #262626;
    }
    p{
      font-size: 14px;
      padding-left: 20px;
      color: #555;
    }
  }
}

.home-login .login-form p.term{
  //@media (min-width: 992px){
    position: relative;
    margin-top: 100px;
  //}
}

.page__buyer{
  @media (min-width: 1200px){
    .container {
      width: 1170px;
    }
  }
  margin-left: 0!important;
}

.container__mobile{
  padding: 15px;
}

.see__more{
  &__content{
    color: #1890FF;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    span{
      margin-left: 5px;
      display: inline-block;
      font-size: 10px;
    }
  }
}

.suggestDefault{
  width: calc(100%);
  position: absolute;
  top: 40px;
  left: 0;
  z-index: 10;
  border-radius: 5px;
  background: #ffffff;
  display: none;
  box-shadow: 0 2px 8px rgba(0,0,0,.15);
  animation: opacityShow .3s;
  &.open{
    display: block;
  }
  &__body{
    padding: 10px;
    max-height: 390px;
    overflow-y: auto;
    .item__product{
      margin-bottom: 10px;
    }
  }
}

.navigation{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  @media (max-width: 991px) {
    display: none;
  }
  &__item{
    padding: 0 5px;
    span{
      color: #8C8C8C;
      &::first-letter{
        text-transform: uppercase;
      }
    }
    &.active{
      span{
        color: #1890FF;
        &::first-letter{
          text-transform: uppercase;
        }
      }
    }
  }
}

#fb-root iframe {
  @media (max-width: 991px) {
    display: none!important;
    z-index: -1!important;
  }
}

.btn-orange {
  background: orange !important;
  border-color: orange !important;
  span {
    color: #ffffff;
  }
}
.item__product {
  .item__image {
    position: relative;
  }
}
.bg_out_of_stock, .bg_out_of_stock_book img {
  width: 115px;
  object-fit: cover;
  height: 115px;
  border-radius: 5px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  &::before {
    content: "";
    background: #000000;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.5;
  }
  span {
    color: #EDEDED;
    z-index: 1;
  }
}
@media (max-width: 768px) {
  .bg_out_of_stock, .bg_out_of_stock_book img {
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
  }
}
.label_out_of_stock{
  background: #E00000;
  border-radius: 2px;
  padding: 2px 5px;
  font-size: 12px;
  font-weight: 400;
  color: white;
  display: inline;
  margin-right: 10px;
}
.label_review{
  background: #f4b63a;
  border-radius: 2px;
  padding: 2px 5px;
  font-size: 12px;
  font-weight: 400;
  color: white;
  display: inline;
  margin-right: 10px;
}
