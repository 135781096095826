.create__order {
  padding-bottom: 110px;
  @media (max-height: 991px) {
    .container{
      padding: 0;
    }
  }
  @media (min-width: 991px) {
    &__content__desktop {
      display: flex;
      .create__order__right{
        background: #ffffff;
        width: 40%;
        flex-shrink: 0;
        margin-right: 15px;
      }
      .create__order__left{
        background: #ffffff;
        width: calc(60% - 15px);
        flex-shrink: 0;
      }
    }
  }
  .item__order {
    .profile__booker__form{
      .form-group{
        .ant-form-item-explain, .ant-form-item-extra{
          min-height: 0;
        }
        .ant-form-item{
          margin-bottom: 15px;
        }
      }
    }
    &.border__bottom{
      border-bottom: 3px solid #F5F5F5;
      @media (min-width: 991px) {
        border-bottom: 3px solid transparent;
      }
    }
    padding: 15px 0;
    h2.title{
      font-size: 16px;
      margin: 10px 0
    }
  }
  .booker__profile{
    margin: 10px 0;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      .change__address {
        color: #1890FF;
        font-size: 14px;
        cursor: pointer;
      }

      h4 {
        font-weight: 600;
        font-size: 16px;
        color: #262626;
        text-transform: uppercase;
      }
    }
    .profile__item{
      display: flex;
      span{
        margin-right: 10px;
        font-size: 14px;
        color: #8C8C8C;
        display: block;
        min-width: 50px
      }
      p{
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
  .profile__booker__form{
    .form-group{
      label{
        font-size: 14px;
        font-weight: normal;
        color: #595959;

      }
    }
  }
  .top__product{
    margin-top: 20px;
    h3{
      font-size: 14px;
      font-weight: 600;
      color: #262626;
    }
    &__content {

    }
    &__item{
      position: relative;
      border-radius: 4px;
      background: #fff;
      margin: 4px;
      padding: 5px;
      width: 104px;
      height: 104px;
      //border: 1px solid #f5f5f5;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .chiet-khau{
        padding: 0 6px;
        border: 1px solid #1890FF;
        background: #FFFFFF;
        border-radius: 4px;
        width: 80px;
        position: absolute;
        bottom: 0;
        right: 8px;
        display: flex;
        align-items: center;
        span{
          display: inline-block;
          margin-left: 5px;
          font-size: 12px;
          font-weight: 500;
        }
      }
      .discount{
        position: absolute;
        top: 0;
        left: 0;
        background: #F5222D;
        font-size: 12px;
        color: #fff;
        border-radius: 4px 0;
        padding: 1px 2px;
      }
    }
  }
  .btn__footer {
    position: fixed;
    bottom: 0;
    background: #ffffff;
    left: 0;
    right: 0;
    z-index: 0;
    box-shadow: 1px 0 8px rgba(0, 0, 0, 0.15);
    padding-top: 15px;
    .total__price{
      display: flex;
      justify-content: space-between;
      span{
        color: #595959;
        font-size: 14px;
      }
      span.price{
        font-size: 18px;
        color: #F5222D;
      }
    }
    @media (min-width: 992px) {
      position: inherit;
      box-shadow: none;
    }
  }
  .step__order{
    display: flex;
    justify-content: center;
    .item__step{
      padding: 0 20px;
      text-align: center;
      .icon{
        padding: 0 30px;

        color: #1890FF;
        margin-bottom: 5px;
        position: relative;
        &:after{
          position: absolute;
          top: 8px;
          left: 75%;
          display: block;
          width: 100%;
          height: 2px;
          background: #f0f0f0;
          content: "";
        }
        .success{
          display: none;
        }
        .step{
          display: block;
          width: 18px;
          height: 18px;
          border-radius: 50%;
          background: #1890FF;
          font-size: 12px;
          color: #ffffff;
          text-align: center;
          margin: auto;
        }
      }
      &.active{
        .icon{
          .success{
            display: block!important;
          }
          .step{
            display: none!important;
          }
          &:after{
            background: #1890FF;
          }
        }
      }
      .text{
        font-weight: 600;
        font-size: 14px;
      }
      &:last-child{
        .icon{
          &:after{
            display: none;
          }
        }
      }
      &.waiting{
        .icon{
          color: #f0f0f0;
          .step{
            background: #f0f0f0;
            color: #1890FF;
          }
        }
      }
    }
  }
}
.product{
  &__content{
    display: flex;
    &.product__one__content{
      padding: 12px 0;
      border-bottom: 1px solid #F5F5F5;
      &:last-child{
        border-bottom: 1px solid transparent;
      }
    }
  }
  &__image{
    width: 72px;
    height: 72px;
    margin-right: 10px;
    img{
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 5px;
    }
  }
  &__detail{
    width: calc(100% - 77px);
    div.name__group {
      display: flex;
      justify-content: space-between;
      .name {
        width: 80%;
      }

      .delete__product{
        font-size: 12px;
        cursor: pointer;
        span{
          margin-right: 5px;
        }
      }
    }
    p.name{
      font-size: 14px;
      font-weight: 600;
      color: #262626;
    }
    p.price__product{
      color: #262626;
      display: flex;
      justify-content: space-between;
      span{
        font-size: 14px;
        color: #262626;

      }
      span.price{
        display: flex;
        align-items: center;
        font-weight: 600;
        svg{
          margin-right: 5px;
        }
      }
      &.commission{
        span.price{
          color: #1890FF;
        }
      }
    }

  }
  &__quantity{
    margin-top: 10px  ;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .quantity{
      display: flex;
      //align-items: center;
      button{
        margin-right: 5px;
      }
    }
    .delete__product{
      font-size: 12px;
      cursor: pointer;
      span{
        margin-right: 5px;
      }
    }
  }
  &__voucher {
    display: flex;
    margin-top: 20px;
    gap: 8px;
    justify-content: space-between;

    .coupon_selector {
        padding: 3px 8px;
        background: #ffe2b8;
        border-radius: 4px;
        font-weight: 700;
        font-size: 12px;
        line-height: 18px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #cc7600;
        cursor: pointer;
    }
  }
}
.booker__form {
  background: #ffffff;
  transition: .4s;
  padding-bottom: 30px;
  &.open{
    transform: inherit;
  }
  &__content{
    margin-top: 20px;
    padding: 0 15px;
    margin-bottom: 100px;
    .form-group{
      label{
        font-weight: 400;
        display: block;
        margin-bottom: 10px ;
      }
      .ant-form-item{
        margin-bottom: 0;
      }
      .ant-form-item-explain{
        min-height: 0;
      }
    }
  }
  .form__btn{
    padding: 15px;
    position: fixed;
    background: #ffffff;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
    right: 0;
    left: 0;
    z-index: 0;
    bottom: 0;
  }
}
