/*loading*/
.zingLoading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    background: rgba(0, 0, 0, .4);
    /*background-color: rgb(34, 160, 234);*/
}
.zingLoading .ant-spin-dot-item{
    opacity: 1!important;
}
/*loading*/


/*========login=========*/
input#basic_username {
    height: 48px;
}
span.ant-input-password.ant-input-affix-wrapper {
    height: 48px;
}
/*========login=========*/
