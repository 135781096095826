@import "login";
@import "register";
@import "forgotPassword";
@import "header";
@import "home";
@import "user";
@import "dashboard";
@import "common";
@import "listProduct";
@import "coupons";
@import "productDetail";
@import "createOrder";
@import "/commom/base";
@import "notification";
@import "order";
@import "checkOut";
@import "yourCart";
@import "/buyer/checkOutStep1";
@import "/buyer/bookingManagement";
@import "dealHot";
@import "top5products";
@import "ListTop";
@import "fonts";

//global font
body {
    font-family: 'Lexend', sans-serif;
}