.box__forgot__password {
  position: fixed;
  top: 0;
  background: #ffffff;
  bottom: 0;
  right: 0;
  left: 0;
  transition: 0.4s;
  transform: translateX(-100%);
  z-index: 10;
  &.open{
    transform: translateX(0);
  }
  .box__header__title {
    background: #FA8C16;
    padding: 12px 0;

    .box__header__title__content {
      display: flex;
      align-items: center;

      h2 {
        font-weight: 400;
        font-size: 16px;
        color: #ffffff;
        width: 100%;
        text-align: center;
      }
    }
  }

  .box__content {
    padding-top: 15px;

    .box__title {
      margin-bottom: 15px;

      h2 {
        font-weight: 600;
        font-size: 16px;
        text-transform: uppercase;
      }
    }
  }

  .form-group {
    margin-bottom: 0;

    label {
      font-weight: 400;
      color: #595959;
    }
  }

  .ant-form-item {
    margin-bottom: 0;
  }
}