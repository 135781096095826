.booking__management{
  &__item{
    padding: 15px;
    .search__product{
      display: flex;
      align-items: center;
      .input__search {
        margin-right: 15px;
      }
    }
  }
  .list__product{
    .product__item{
      padding: 15px;
      border-bottom: 1px solid #D9D9D9;
      .product__info{
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        .order__code{
          font-size: 12px;
          color: #8C8C8C;
          span{
            color: #1890FF;
            font-size: 12px;
          }
        }
        .order__status{
          font-size: 12px;
          &.success{
            color: #52C41A;
          }
          &.error{
            color: #F5222D;
          }
          &.pending {
            color: #FFD591;
          }
        }

      }
      .product__content{
        .product__text{
          width: calc(100% - 83px);
          .name{
            font-weight: 600;
            font-size: 14px;
            color: #262626;
          }
          .net__price{
            display: flex;
            justify-content: space-between;
            .price{
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}