.your__cart{
  background: #ffffff;
  transition: .4s;
  &__content{
    padding-bottom: 120px;
    padding-top: 20px;
  }
  .cart__item{
    h2{
      font-size: 16px;
      font-weight: 600;
      margin: 10px 0;
      text-transform: uppercase;
    }
    .product__content {
      padding: 12px 0;
    }
    border-bottom: 2px solid #F5F5F5;
    &:last-child{
      border-bottom: 2px solid transparent;
    }
  }
  .form__btn{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
    background: #ffffff;
    padding: 15px;
    .net__price{
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      p,span{
        font-size: 14px;
        margin-bottom: 0;
      }
      span{
        color: #595959;
      }
      p.price{
        color: #F5222D;
        font-weight: 600;
      }
    }
    button{
      font-weight: 600;
    }
  }
}