.product_detail {
  .container {
    padding: 0;
  }

  padding-bottom: 110px;

  .box__header__title {
    @media (min-width: 992px) {
      display: none;
    }
  }

  .product_detail__content {
    background: #ffffff;
    display: flex;
    padding: 15px;

    @media (max-width: 991px) {
      padding: 0;
      display: inherit;
    }

  }

  .image__mobile {
    position: relative;
    display: none;

    @media (max-width: 991px) {
      display: block;

      .index__image {
        border-radius: 5px;
        background: rgba(46, 58, 89, 0.31);
        position: absolute;
        bottom: 10px;
        right: 20px;
        z-index: 10;
        color: #ffffff;
        padding: 1px 15px;
      }
    }

  }

  .image__desktop {
    @media (max-width: 991px) {
      display: none;
    }

    .group-images {

      display: flex;

      .images {
        margin-right: 15px;

        .images__item {
          width: 72px;
          height: 72px;
          margin-bottom: 10px;
          cursor: pointer;

          .background {
            position: absolute;
            background: rgba(0, 0, 0, 0.7);
            top: 0;
            height: 100%;
            z-index: 1;
            color: #ffffff;
            font-size: 11px;
            text-align: center;
            display: flex;
            align-items: center;
            border-radius: 4px;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            aspect-ratio: 1;
            border-radius: 4px;
            border: 1px solid rgb(204, 204, 204);
          }

          &.active {
            img {
              border: 1px solid #1890FF;
            }
          }
        }
      }

      .js-image-zoom__zoomed-image {
        background-size: 100% !important;
      }

      .js-image-zoom__zoomed-area {
        width: 150px !important;
        height: 150px !important;
        background: #00ab6b;
      }

      .thumbnail {
        width: 432px;
        height: 100%;
        text-align: center;

        &>div {
          z-index: 2;

          img {
            object-fit: contain;
          }
        }

        .zoom {
          margin: 15px 0;
        }
      }
    }

    .image-gallery-slides {
      height: 400px;
    }
  }

  &__content {
    .product__description {
      padding: 15px;

      @media (min-width: 992px) {
        padding: 15px 0;
      }

      .content {
        margin-bottom: 10px;
        font-size: 14px;
        overflow: hidden;
        height: 190px;

        img {
          width: 100%;
          height: auto;
          object-fit: cover;
          display: block;
        }

      }

      &.open {
        .content {
          height: auto;
        }
      }
    }

    .product__tranform {
      padding: 15px;

      @media (min-width: 992px) {
        padding: 10px 0;
      }

      .content {
        font-size: 14px;
        overflow: hidden;

        img {
          width: 100%;
          height: auto;
          object-fit: cover;
          display: block;
        }

      }

      &.open {
        .content {
          height: auto;
        }
      }
    }

    @media (min-width: 992px) {
      .detail__info {
        width: 50%;
      }

      .detail__image {
        width: 50%;
      }

      &.pt-0 {
        padding: 0;
      }

      .container {
        padding: 0;
      }

      .product__description {
        .content {
          height: auto;

          img {
            // width: 200px;
            // height: 200px;
            object-fit: cover;
            display: block;
          }

        }
      }
    }
  }

  &__item {
    padding: 15px 0;

    .item__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;

      h2 {
        font-weight: 600;
        font-size: 16px;
        text-transform: capitalize;
      }

      .text__transform {
        //text-transform: uppercase;
      }
    }

    &.border__bottom {
      border-bottom: 4px solid #F5F5F5;
    }
  }

  .detail__item {
    overflow: hidden;
  }

  .warning {
    background: #FFF2E8;
    text-align: center;

    .button__warning {
      color: #FA541C;
      cursor: pointer;

      span {
        margin-left: 5px;
      }
    }

    .box__backdrop {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      transition: .4s ease-in-out;
      background: #000000;
      visibility: hidden;
      opacity: 0;
      z-index: 99;
    }

    .box__warning {
      position: fixed;
      text-align: left;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 8px 8px 0px 0px;
      visibility: hidden;
      z-index: 100;
      transition: .4s ease-in-out;

      &__content {
        transform: translateY(100%);
        background: #fff;
        transition: .4s ease-in-out;
        padding: 15px;
        border-radius: 8px 8px 0px 0px;

        .box__title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 15px;

          .box__close {
            color: #BFBFBF;
            font-size: 20px;
          }

          h2 {
            color: #262626;
            font-size: 16px;
            font-weight: 600;
            text-transform: uppercase;
            margin: 0;

          }
        }

        .text__content {
          h2 {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 10px;
          }

          ul {
            list-style: inherit;
            color: #BFBFBF;
            padding-left: 15px;

            li {
              color: #595959;
              margin-bottom: 10px;
            }
          }
        }
      }
    }

    &.open {
      .box__backdrop {
        visibility: visible;
        opacity: 0.8;
      }

      .box__warning {
        &__content {
          transform: translateY(0);
          visibility: visible;
        }
      }
    }
  }

  .price_range {
    border: 1px solid #BFBFBF;
    border-radius: 5px;

    /* Create three equal columns that floats next to each other */
    .column_price_range {
      float: left;
      width: 33.33%;
      padding: 5px;

    }

    /* Clear floats after the columns */
    .row_price_range:after {
      content: "";
      display: table;
      clear: both;
    }

    .text_center {
      text-align: center;
      align-items: center;
      justify-content: center;
      display: flex;
    }

    .text_header {

      margin-left: 10px;

    }

    .text_line_throught {
      color: #1890FF;
      text-decoration-line: underline;
      text-decoration-style: solid;
      text-decoration-color: #1890FF;
    }
  }

  .detail__text {
    padding: 15px;

    @media (min-width: 992px) {
      padding: 0;
    }

    p {
      display: flex;
      color: #595959;
      font-size: 14px;

      strong {
        margin-left: 5px;
      }

      .chiet__khau {
        display: flex;
        align-items: center;
        color: #1890FF;

        span {
          margin-left: 5px;
        }
      }
    }

    p.price__buyer {
      align-items: center;

      .discount {
        padding: 3px 5px;
        background: #F5222D;
        color: #ffffff;
        font-size: 12px;
        border-radius: 5px 0;
        margin-right: 10px;
      }

      .price {
        font-size: 20px;
        font-weight: 600;
        color: #262626;
        margin-right: 10px;
      }

      .price__before__discount {
        text-decoration: line-through;
        font-weight: 600;
        font-size: 16px;
        color: #8C8C8C;
      }
    }
  }

  .role_publisher {
    @media (min-width: 992px) {
      display: none;
    }

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    background: #FFFBE6;
    border: 1px solid #FFD591;
    border-radius: 5px;

    p {
      font-size: 16px;
      color: #262626;
      font-weight: 600;
    }

    span {
      color: #595959;
      font-size: 14px;
    }
  }

  .share__product {
    &.border__bottom {
      @media (min-width: 991px) {
        border: none;
      }
    }

    &.share__desktop {
      @media (max-width: 991px) {
        display: none;
      }
    }

    &.share__mobile {
      @media (min-width: 992px) {
        display: none;
      }
    }

    .list__payment__share {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      .item__share {
        padding: 0 5px;
        cursor: pointer;
        border: 1px solid #40A9FF;
        border-radius: 4px;
        color: #40A9FF;
        width: 200px;
        height: 40px;
        line-height: 39px;

        span {
          padding: 0px 8px;
        }
      }

      @media (min-width: 768px) {
        justify-content: inherit;

        .item__share {
          margin-right: 10px;
        }
      }
    }

    &.open {
      .box__share__content {
        transform: translateY(0);
        visibility: visible;
      }

      .box__backdrop {
        visibility: visible;
        opacity: 0.8;
      }
    }
  }

  .btn__order__desktop {
    width: 200px !important;

    @media (max-width: 991px) {
      width: 100% !important;
    }
  }

  .ant-btn[disabled] {
    background: #1890FF !important;
    border-color: #1890FF !important;
    color: #fff !important;
    opacity: .5 !important;

  }


  .ant-btn[disabled] {
    background: #1890FF !important;
    border-color: #1890FF !important;
    color: #fff !important;
    opacity: .5 !important;
  }


  .box__footer {
    @media (min-width: 992px) {
      display: none;
    }

    position: fixed;
    box-shadow: 1px 0 8px rgba(0, 0, 0, .15);
    bottom: -3px;
    z-index: 3;
    right: 0;
    left: 0;
    padding: 0 15px;
    background: #ffffff;

    .btn__group {
      display: flex;
      justify-content: space-between;

      button {
        width: calc(50% - 5px);
      }

      @media (max-width: 350px) {
        .ant-btn {
          padding: 0;

          span {
            font-size: 13px;
          }
        }
      }
    }
  }

  .share__content {
    padding: 0 15px;

    .content {
      height: 190px;
      overflow: hidden;
    }

    .list__content {
      margin-bottom: 15px;
      width: 100%;
      overflow-x: auto;
      white-space: nowrap;
      border-bottom: 1px solid #8C8C8C;

      .item__content {
        padding: 5px 15px;
        display: inline-block;
        width: 32%;
        border-bottom: 2px solid transparent;
        text-align: center;

        &.active {
          border-bottom: 2px solid #1890FF;

          span {
            color: #1890FF;
          }
        }

        span {
          padding: 5px;
        }
      }
    }

    &.open {
      .content {
        height: auto;
      }
    }

    .btn__group {
      display: flex;
      justify-content: space-between;

      button {
        width: calc(50% - 10px);
      }
    }
  }

  .product__same_left {
    @media (max-width: 991px) {
      width: 100%;
    }

    @media (min-width: 991px) {
      width: 66.67%;
      padding-right: 15px;
      display: inline-block;
    }
  }

  .product__same_right {
    @media (max-width: 991px) {
      width: 100%;
    }

    @media (min-width: 991px) {
      width: 33.33%;
      float: right;
    }
  }

  .product__same {
    padding: 15px;
    margin-top: 15px;
    background: #ffffff;
    min-height: 360px;

    h4 {
      font-size: 16px;
    }

    @media (max-width: 767px) {
      display: inline-block;
    }

    @media (min-width: 768px) {
      display: inline-block;
    }

    .item__product {
      //width: calc(100% / 6);
      width: 170px;
      border: none;

      img {
        width: 115px;
        object-fit: contain;
        margin-bottom: 10px;
        height: 115px;
        border-radius: 5px;
      }
    }

    @media (max-width: 991px) {
      .item__product {
        // width: 20%;
        width: 170px;
        border: none;

      }
    }

  }

  .product__video-container {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 */
    height: 0;
  }

  .product__video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .product_coupons_slider {
    margin-bottom: 10px;
    margin-top: 10px;
  }

  @media (max-width: 768px) {
    .bg_out_of_stock {
      transform: unset;
      top: unset;
      left: unset;
    }
    .product_coupons_slider {
        margin-top: 10px;
        margin-bottom: 10px;
      }
  }
}

// Variations
.group-variations {

  margin-top: 10px;

  .group-variations-content {
    margin-bottom: 8px;

    .variation-title {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 5px;
      text-transform: capitalize;
    }

    .variation-content {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;

      .variation-value {
        width: 100px;
        text-transform: capitalize;
        text-align: center;
        font-size: 14px;
        margin-bottom: 5px;
        border: 1px solid #899296;
        box-sizing: border-box;
        border-radius: 8px;
        font-size: 12px;
        padding: 4px 12px;
        margin-right: 4px;
        cursor: pointer;

        &.active {
          background: rgba(68, 189, 50, .2);
          border: 1px solid #44bd32;
          color: #44bd32;
        }

        &.disabled {
          pointer-events: none;
          opacity: 0.3;
          // background: #fc4b4b;
        }
      }
    }
  }

  .group-variations-price {
    font-size: 14px;
    margin-bottom: 8px;

  }

  .group-variations-price-value {

    .price-group {
      margin-bottom: 8px;
      align-items: center;
      .price-title {
        margin-right: 4px;
        font-weight: 600;

      }

      .price-value {
        font-size: 14px;
        font-weight: 700;
        font-size: 16px;
      }
    }

    .comm-group {
      gap: 5px;
      align-items: center;
      margin-bottom: 8px;

      .comm-title {
        font-weight: 600;
      }

      .comm-value {
        font-size: 14px;
        font-weight: 700;
        color: #1890FF;
      }
    }
  }
}