.user-info {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #ffffff;
  z-index: 10;
  transition: .4s;
  transform: translateX(-100%);
  &.open {
    transform: translateX(0);
  }

  &__header {
    background: #FA8C16;
    padding: 12px 0;

    .user-info__header__title {
      display: flex;
      align-items: center;

      h2 {
        font-weight: 400;
        font-size: 16px;
        color: #ffffff;
        width: 100%;
        text-align: center;
      }
    }
  }

  &__content {
    .user__info__item {
      border-bottom: 4px solid #F5F5F5;
      padding: 16px 0;

      .title {
        h2 {
          font-size: 14px;
          font-weight: 600;
          color: #262626;
          text-transform: uppercase;
        }

        margin-bottom: 10px;
      }

      .title-2 {
        h2 {
          font-size: 14px;
          font-weight: 600;
          color: #262626;
          text-transform: uppercase;
        }

        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .user__item__content {
        .info__item {
          padding: 9px 0;
        }
      }
    }

    .user_info {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .user_info_content {
        display: flex;
        align-items: center;

        .user_name {
          margin-left: 15px;

          p.name {
            font-size: 16px;
            font-weight: 500;
          }

          p.time-join {
            font-size: 14px;
          }
        }

        p {
          margin-bottom: 0;
          color: #262626;

        }
      }
    }

    .user__info_share.open {
      .box__share__content {
        transform: translateY(0);
        visibility: visible;
      }

      .box__backdrop {
        visibility: visible;
        opacity: 0.8;
      }
    }

    .basic__info {
      padding: 16px 10px;
    }
  }
}


.account {
  transition: 0.4s;
  background: #ffffff;
  z-index: 1;
  //box-shadow: 1px 0 8px rgba(0, 0, 0, .15);
  @media (min-width: 992px) {
    background: transparent;
  }
  &__content {

    .account__item {
      border-bottom: 4px solid #F5F5F5;
      padding: 16px 0;

      .title {
        h2 {
          font-size: 14px;
          font-weight: 600;
          color: #262626;
          text-transform: uppercase;
        }

        margin-bottom: 10px;
      }

      .title-2 {
        h2 {
          font-size: 14px;
          font-weight: 600;
          color: #262626;
          text-transform: uppercase;
        }

        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .user__item__content {
        .info__item {
          padding: 9px 0;
        }
      }
    }

    .user_info {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .user_info_content {
        display: flex;
        align-items: center;

        .user_name {
          margin-left: 15px;

          p.name {
            font-size: 16px;
            font-weight: 500;
          }

          p.time-join {
            font-size: 14px;
          }
        }

        p {
          margin-bottom: 0;
          color: #262626;

        }
      }
    }

    .account_share.open {
      .box__share__content {
        transform: translateY(0);
        visibility: visible;
      }

      .box__backdrop {
        visibility: visible;
        opacity: 0.8;
      }
    }

    .basic__info {
      padding: 16px 10px;
    }
  }
  &__desktop{
    display: flex;
    justify-content: space-between;
    @media (max-width: 991px) {
      display: block;
    }
    &__right{
      width: 30%;
      background: #ffffff;
      border-radius: 4px;
      padding: 15px;
      @media (max-width: 991px) {
        width: 100%;
      }
      .user_info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 15px;
        border-bottom: 1px solid #d9d9d9;

        .user_info_content {
          display: flex;
          align-items: center;

          .user_name {
            margin-left: 15px;

            p.name {
              font-size: 16px;
              font-weight: 500;
            }

            p.time-join {
              font-size: 14px;
            }
          }

          p {
            margin-bottom: 0;
            color: #262626;

          }
        }
      }
      .menu__account{
        padding-top: 15px;
        .item__account{
          margin-bottom: 15px;
          cursor: pointer;
          &.active{
            span{
              color: #1890FF;
            }
          }
          svg{
            margin-right: 10px;
          }
        }
      }
    }
    &__left{
      width: calc(70% - 15px);
      background: #ffffff;
      border-radius: 4px;
      padding: 15px;
      @media (max-width: 991px) {
        width: 100%;
      }
      .basic__info__desktop{
        border: none;
      }
    }
    @media (min-width: 991px) {
      .user-info, .edit__user__info{
        position: relative;
        transform: translateX(0);
        z-index: 0;
      }
    }
  }
}

.edit__user__info {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
  background: #ffffff;
  transition: .4s;
  transform: translateX(-100%);
  overflow: scroll;

  &.open {
    transform: inherit;
  }

  &__content {
    margin-bottom: 120px;

    .title {
      margin-top: 25px;
      font-size: 16px;
      font-weight: 600;
      text-transform: uppercase;
    }

    .form-group {
      label {
        font-weight: 400;
        font-size: 14px;
        color: #595959;
      }
    }

    .form__btn {
      //position: fixed;
      margin-top: 30px;
      bottom: 0;
      z-index: 11;
      //padding: 15px;
      background: #fff;
      width: 100%;
      left: 0;
      box-shadow: 1px 0 8px rgba(0, 0, 0, .15);
    }
    .ant-form-item-explain, .ant-form-item-extra{
      min-height: 0px;
    }
  }
}

.tutorial__account {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ffffff;
  z-index: 10;
  overflow: scroll;
  transition: .4s;
  transform: translateX(-100%);
  &.open{
    transform: inherit;
  }
  &__content {
    margin-top: 30px;
    padding-bottom: 70px;
    position: relative;
    .list__content {
      .item__content__rule {
        font-size: 14px;
        display: flex;
        color: #262626;
        margin-bottom: 15px;

        .step {
          border-radius: 4px;
          height: 28px;
          width: 28px;
          margin-right: 10px;
          background: #1890FF;
          padding: 2px 4px;
          color: #ffffff;
          font-weight: 600;
          text-align: center;
          line-height: 25px;
        }

        p {
          width: calc(100% - 38px);
        }
      }

      h4 {
        font-size: 16px;
        font-weight: 600;
        color: #262626;
      }

      p {
        font-size: 14px;

        span {
          color: #1890FF;
          font-weight: 600;
        }
      }
    }
    p{
      display: block!important;
    }
    .ant-collapse{
      margin-bottom: 30px;
      background-color:#ffffff;
    }
    .header__panel {
      h4 {
        font-size: 20px;
        font-weight: 600;
        color: #262626;
      }

      p {
        margin: 0;
        font-size: 14px;
        color: #595959;
      }
    }

  }
  .form__btn{
    //position: absolute;
    bottom: 0;
    //z-index: 10;
    padding: 15px;
    //left: 0;
    width: 100%;
    background: #ffffff;
    //box-shadow: 1px 0 8px rgba(0, 0, 0, .15);
  }
}

.mr-10 {
  margin-right: 10px;
}

.btn-logout {
  color: #40a9ff;
  border: 1px solid #40a9ff;
}

@keyframes toPage {
  from { transform: translateX(-100%);}
  to {  transform: translateX(0);}
}

@keyframes hidePage {
  from { transform: translateX(0);}
  to {  transform: translateX(-100%);}
}
