$mobile-width: 576px;
$tablet-width: 768px;
$primary-color: #44bd32;
$grey1: #6c757d;
$grey2: #e5ebed;
$grey3: #f5f5f5;

@mixin mobile {
  @media (max-width: #{$mobile-width}) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: #{$tablet-width}) {
    @content;
  }
}
.wrapper__coupons {
    
        .coupon-list {
            gap: 15px;
            padding: 0 16px;
            margin: 16px 0;
            display: flex;
            flex-wrap: wrap;

            @media (max-width: 1024px) and (min-width: 768px) { 
                margin: 16px auto;
                width: 680px;

            }
            @media (max-width: 768px) { 
                display: block;

                .coupon__item {
                    margin-bottom: 10px;
                }
            }
    
        }
        .header-title {
            padding-bottom: 15px;
            border-bottom: 1px solid #E8E8E8;

            h2 {
                padding: 15px;
                text-transform: uppercase;
                font-size: 16px;
                font-weight: 600;
            }
        }
        .home__product {
            .item__product {
                width: 145px;
            }
            img {
                width: 115px;
                object-fit: contain;
                margin-bottom: 10px;
                height: 115px;
                border-radius: 5px;
            }
            @media (max-width: 393px) {
                width: 81% !important;
                margin: 10px auto;
              }
            @media (max-width: 768px) {
                width: 75%;
                margin: 10px auto;
            }
        }
    }
.coupon__item {
    display: flex;
    background: #FFFFFF;
    border: 1px solid #E5EBED;
    border-radius: 6px;
    padding: 12px;
    align-items: center;
    width: 310px;
    p {
        font-size: 13px;
        font-weight: 700;
    }
    span {
        font-size: 10px;
    }

    &__title {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px;
        width: 100px;
        cursor: pointer;

        p {
            color: #308523 !important;
        }
        span {
            color: #757575;
            font-weight: 600;
        }
    }
    &__detail {
        width: calc(100% - 100px);
        .sale {
            color: #1877f2 !important;
            line-height: 24px;
            margin: 0;
        }
        .description {
            font-size: 12px;
            margin-bottom: 8px;
            word-break: break-word;
        }
        span {
            color: #212121;
        }
        .exp-coupon {
            display: flex;
            gap: 4px;
            justify-content: space-between;

            .exp-time {
                display: flex;
                color: #595959;
                font-size: 12px;
                font-weight: 600;

                img {
                    width: 15px;
                    height: 15px;
                    margin-right: 5px;
                }
            }
            .buy-now {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                grid-gap:4px;
                gap: 4px;
                background: #1890FF;
                border-radius: 8px;
                color: #fff;
                font-size: 12px;
                padding: 8px;
                cursor: pointer;
            }
            .btn-disable-txt {
                width: 80px !important;
                background: #6c757d;
                color: #fff;
                font-size: 12px;
                padding: 8px;
                border-radius: 8px;
            }
        }
    }

    @media (max-width: 768px) { 
        margin-bottom: 10px;
        margin: 0 auto;
    }

}

.modal__coupon-details {
    padding: 16px;
    border-radius: 8px;
    width: 40% !important;
    .ant-modal-header {
        .ant-modal-title {
            color: #1b1b1b;
            font-weight: 500;
            text-align: center;
            text-transform: uppercase;
        }
        border-radius: 8px 0px;
    }
    .ant-modal-body {
        max-height: 400px;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            width: 2px;
            height: 1px;
            display: none;
          }
          
          /* Track */
        &::-webkit-scrollbar-track {
            border-radius: 10px;
          }
           
          /* Handle */
        &::-webkit-scrollbar-thumb {
            background: grey; 
            border-radius: 10px;
          }

          @media (max-width: 768px) {
            font-size: 10px;
            padding: 8px;
        }
    }

    .cp-detail-body {
        
        .cp_group {
            &:not(:last-child) {
                margin-bottom: 10px;
                padding-bottom: 10px;
                border-bottom: 1px solid $grey2;
            }

            .cp-code,
            .cp-time {
                display: flex;

                .left,
                .right {
                    width: 50%;
                }
            }

            .cp-code {
                .right {
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 21px;
                    color: #308523;
                }
            }

            .cp-time {
                .right {
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 120%;
                    color: #000;
                }
            }


            &.cp-detail {
                .time {
                    color: #000;
                }
            }
        }
    }

    @media (max-width: 768px) {
        width: 100% !important;
    }

}
#box_coupons {
    .swiper-wrapper {
        .swiper-slide {
            width: 310px !important;
        }
    }
}
.voucher {
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    .anticon-right {
        padding: 4px;
    }
}
.modal-list-coupon {
    width: auto;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 10px;

    h3 {
        line-height: 21px;
        color: #1877f2;
        font-size: 14px;
    }

    .left {
        width: 30%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .cp-code {
            font-size: 16px;
            line-height: 21px;
            color: #308523;
            font-weight: 700;
            word-break: break-word;
        }
        img {
            margin-right: 5px;
        }
    }

    .right {
        display: flex;
        flex-direction: column;

        align-items: flex-start;
        width: calc(70% - 50px);

        .coupon-item-desc {
            word-break: break-word;
        }
    }
    .show-more-title {
        width: calc(70% - 80px) !important;
    }

    .coupon-item-btn {

        button {
            width: 80px !important;
            padding: 4px 8px;
            background: #1890FF;
            border-radius: 8px;
            color: #FFFFFF;
            font-size: 12px;
            padding: 8px;
            cursor: pointer;

            &:disabled {
                color: #fff;
                background-color: $grey1;
                border-color: $grey1;
            }
        }
        button.cancel {
            background:#6c757d;
        }
    }

    @media (max-width: 768px) {
        .left {
            .cp-code {
                font-size: 12px;
                line-height: 13px;
            }
        }
        h3 {
            font-size: 12px;
            line-height: 13px;
        }
    }

}
.coupon-more {
    display: flex;
    justify-content: space-between;
    margin: 10px auto;

    .more {
        color: #1890FF !important;
        align-items: center;
        cursor: pointer;
    }
}
#coupon__end {
    text-align: center;
    width: 100%;
    margin-top: 10px;
    font-size: 15px;
    color: #1890FF;
}