.home-login .login-form {
  margin-top: 40px;
  .ant-form-item {
    margin-bottom: 5px;
  }
  .ant-form-item-control-input{
    text-align: left;
  }
  @media (max-width: 991px) {
    margin-top: 50px;
  }
}
.home-login{
  height: 100%;
  overflow: hidden;
  &__content{
    width: 500px;
    margin: 50px auto;
    .header-logo{
      margin: 0 auto;
      width: 102px;
      img{
        width: 100%;
      }
    }
    .login-title {
      margin-top: 30px;
      text-transform: inherit;
      p{
        text-transform: initial;
        color: #595959;
        font-size: 14px;
        margin-top: 10px;
      }
    }
    @media (max-width: 991px) {
      width: 100%;
      margin-top: 24px;
      .login-title{
        margin-top: 12px;
        text-transform: uppercase;
      }
    }
  }
  .ant-form-item-explain, .ant-form-item-extra{
    text-align: left;
    min-height: 0px;
  }
  .form-group.ant-form-item{
    margin-bottom: 12px;
  }
}
