.content__deal__hot {
  padding: 16px;

  .deal__hot__info {
    padding-right: 25px;

    .card {
      background: #FFFFFF;
      padding: 16px;
      margin-top: 5px;

      .label-title{
        color: #262626;
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;
        margin: 0;
      }

      .frame-image{
        background: url("../images/ic-loading.gif") no-repeat;
        background-size: 40px 40px;
        background-position:center center;
        width: 100%;
        aspect-ratio: 3/1;
        
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .card__item {
        padding-top: 16px;

        .label {
          font-size: 16px;
          font-weight: bold;
          color: #424242;
          line-height: 22px;
        }

        p {
          padding-top: 8px;
          margin: 0;
          font-size: 14px;
          line-height: 22px;
          color: #616161;

          &:first-child {
            padding-top: 0;
          }
        }

        .condition_apply{
          font-weight: bold;
          font-size: 16px;
          line-height: 19.2px;
          color: #424242;
        }

        &.no-padding{
          padding-top: 0;
        }
      }

      &:first-child {
        margin-top: 0;
      }

      .row{
        margin-right: -16px;
        & > .deal__item__box{
          padding: 16px 16px 0 0;

          & > .frame__img__item{
            display: flex;
            border: 1px solid #DFDFDF;
            border-radius: 8px;
            text-align: center;
            height: 100%;
            background: url("../images/ic-loading.gif") no-repeat;
            background-size: 40px 40px;
            background-position:center center;

            & > img{
              width: 100%;
              max-height: 100%;
              border: 1px solid #DFDFDF;
              border-radius: 8px;
              overflow: hidden;
            }
          }
        }
      }
    }

    .product__item{
      margin: 8px;
      background-clip: content-box;
      background: #ffffff;
      border-radius: 8px;
      overflow: hidden;
      .bg_out_of_stock {
        width: 100% !important;
        height: 100% !important;
      }
      a{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        position: relative;
        img{
          max-height: 182px;
        }
      }

      p{
        padding: 10px 5px 0 5px;
        margin: 0;
        color: #262626;

        &:first-child{
          font-size: 12px;
          line-height: 18px;
        }

        &:nth-child(2){
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
        }

        &:last-child{
          font-weight: bold;
          font-size: 12px;
          line-height: 18px;
          color: #1890FF;
          padding-bottom: 10px;

          span{
            font-size: 12px;
            margin-right: 5px;
          }
        }
      }

    }
  }

  .deal__hot__total{
    padding: 12px 16px;
    background: #ffffff;
    box-shadow: 1px 0 8px #00000026;
    border-radius: 4px;
    color: rgba(38, 38, 38, 1);

    .label{
      display: flex;
      justify-content: space-between;
      padding: 0 17px;

      span{
        text-transform: uppercase;
        font-weight: 400;
        line-height: 24px;
        font-size: 14px;
      }

      a{
        line-height: 22px;
        font-size: 14px;
        display: block;
      }
    }

    .price{
      padding: 0 17px;
      font-weight: 600;
      font-size: 28px;
      line-height: 32px;
    }
  }

  .top__product{
    padding: 16px;
    color: rgba(38, 38, 38, 1);
    background: #ffffff;

    h3{
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 16px;
    }

    .top__product__item{
      display: flex;
      align-items: center;
      color: rgba(0, 0, 0, 1);
      margin-top: 33px;

      &:first-child{
        margin-top:0;
      }

      span{
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        padding-right: 16px;
      }

      .image{
        width: 56px;
        height: 56px;
        border: 1px solid #E8E8E8;
        border-radius: 4px;
        margin-right: 16px;

        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .text{
        width: calc(100% - 98px);
        font-size: 14px;

        p:first-child{
          font-weight: 500;
        }

        p:nth-child(2) {
          color: #595959;

          b{
            color: #262626;
          }
        }
      }
    }
  }

  .btn_load_more_deal{
    margin: 8px;
  }

  .btn_load_deal{
    margin-top:16px
  }
}

.eclips-text{
  white-space: nowrap;
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 991px) {
  .col-12{
    width: 100%;
  }

  .content__deal__hot .deal__hot__info{
     padding-right: 0;
  }
}

@media (max-width: 1024px) {
  .content__deal__hot .deal__hot__info .product__item a img{
    height: 8rem;
  }

  .content__deal__hot .deal__hot__info .card .row > .deal__item__box > .frame__img__item{

  }
}

@media (max-width: 425px) {
  .content__deal__hot .deal__hot__info .card .frame-image{
    height: unset;
    max-height: 337px;
  }
}

@media (max-width: 768px) {
  .content__deal__hot .card.card-mobile{
    padding: 0 !important;
  }
  .content__deal__hot .deal__hot__info .card.card-mobile .row > .deal__item__box > .frame__img__item{
    min-height: 117px;
  }
  .content__deal__hot .card{
    padding: 0 8px !important;
  }
}

@media (min-width: 320px) and (max-width: 576px) {
  .col-xs-6{
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 576px) {
  .col-sm-3{
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media (min-width: 992px) {
  .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-md-3{
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media (max-width: 1440px) {
  .deal__item__box > .frame__img__item{
     max-height: 160px;
  }
}




