.register{
  text-align: center;
  &__content{
    width: 500px;
    margin: auto;
    @media (max-width: 991px) {
      width: 100%;
    }
  }
}
.register-form {
  margin-top: 25px;
  text-align: left;
  .form-group{
    margin-bottom: 0px;
    label{
      font-weight: 400;
      color: #595959;
    }
  }
  .form-checkbox{
    margin-bottom: 10px;
  }
  .ant-checkbox + span{
    font-size: 13px;
  }
  .ant-form-item{
    margin-bottom: 0;
  }


  //.form-checkbox.open{
  //  .rule__for__partner{
  //    transform: translateY(0);
  //    visibility: visible;
  //  }
  //}
}

.rule__for__partner{
  position: fixed;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  transform: translateY(100%);
  visibility: hidden;
  transition: .4s;
  background: #ffffff;
  z-index: 100;
  //padding: 15px;
  &.open{
    transform: translateY(0);
    visibility: visible;
  }
  .close__popup{
    text-align: right;
    cursor: pointer;
    color: #595959;
    padding: 15px 15px 0 15px;
  }

}
.rule__content{
  @media (min-width: 991px) {
    padding-bottom: 20px;
  }
  .ant-modal-body{
    padding: 30px 0;
  }
  &__item {
    padding: 15px;
    .images__rule{
      text-align: center;
      margin: 25px 0 30px 0;
      img{
        margin: auto;
      }
    }
    .rule__content__one__text{
      .rule__title{
        text-align: center;
        margin-bottom: 30px;
        h3{
          font-weight: 600;
          font-size: 20px;
        }
        span{
          color: #595959;
          font-size: 14px;
        }
      }
      .list__content{
        .item__content__rule{
          font-size: 14px;
          display: flex;
          color: #262626;
          margin-bottom: 15px;
          .step{
            border-radius: 4px;
            height: 28px;
            width: 28px;
            margin-right: 10px;
            background: #1890FF;
            padding: 2px 4px;
            color: #ffffff;
            font-weight: 600;
            text-align: center;
            line-height: 25px;
          }
          p{
            width: calc(100% - 38px);
          }
        }
        h4{
          font-size: 16px;
          font-weight: 600;
          color: #262626;
        }
        p{
          font-size: 14px;
          span{
            color: #1890FF;
            font-weight: 600;
          }
        }
      }
    }
    .form__button{
      display: flex;
    }
  }
  .mb-20{
    margin-bottom: 20px!important;
  }
  .mb-25{
    margin-bottom: 25px!important;
  }
  .mb-30{
    margin-bottom: 30px!important;
  }
}
.slick-slide img{
  display: initial;
}
.slick-dots {
  position: absolute;
  left: 0;
  right: 0;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li button {
  border: 0;
  display: block;
  outline: 0;
  font-size: 0;
  width: 10px;
  height: 10px;
  background: #d9d9d9;
  cursor: pointer;
  border-radius: 50%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li.slick-active button {
  background: #1890FF;
}