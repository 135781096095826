.list-top5 {
    align-items: flex-start;
    .itemCard {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;

        display: flex;
        color: #3d3f40;
        height: 100%;
        position: relative;
        .images {
            width: 100px;
            height: 100px;
            img {
                width: 100px;
                height: 100px;
                border-radius: 4px;
                border: 1px solid #e8e8e8;
                box-sizing: border-box;
                object-fit: contain;
            }
        }

        .text-group {
            width: 100%;
            .title {
                display: -webkit-box;
                overflow: hidden;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }
        .comm {
            font-size: 12px;
            line-height: 18px;
            color: #0097e6;
        }
        .percent {
            span {
                margin-right: 6px;
                color: #ffffff;
                font-weight: 700;
                font-size: 10px;
                line-height: 15px;
            }
            background: #da4f25;
            border-radius: 16px;
            padding: 2px;
            position: absolute;
            top: 4px;
            left: 4px;
            justify-content: space-between;
            display: flex;
            align-items: center;
        }
        .salePrice {
            font-weight: 700;
            font-size: 13px;

            color: #3d3f40;
        }
        .originPrice {
            font-style: normal;
            font-size: 12px;

            text-decoration-line: line-through;
            color: #899296;
            margin-right: 8px;
        }
        .discount {
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
    }
}
.filter_label {
    display: flex;
    .label {
        border: 1px solid #899296;
        box-sizing: border-box;
        border-radius: 8px;
        font-size: 12px;
        padding: 4px 12px;
        margin-right: 4px;
        cursor: pointer;
    }
    .label.active {
        background: rgba(68, 189, 50, 0.2);
        border: 1px solid #44bd32;
        color: #44bd32;
    }
}
.title-hot-product {
    display: flex;
    margin-bottom: 12px;
    margin-left: -16px;
    margin-right: -16px;
    padding: 0 16px 12px 16px;
    background: #ffffff;
    box-shadow: inset 0px -1px 0px rgba(61, 63, 64, 0.1);
    border-radius: 4px 4px 0px 0px;
    align-items: center;
    justify-content: space-between;
    img {
        cursor: pointer;
    }
    .title {
        padding-left: 0 !important;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        text-transform: capitalize !important;
        font-size: 14px;
        color: #5c6366;
    }
}

.home .hot__product__item {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 0 !important;
}
.top__product {
    .images {
        margin-right: 10px;
        img {
            object-fit: cover;
        }
    }
    .itemCard {
        margin-bottom: 23px;
    }
}
