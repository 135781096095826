.orderAdmin__table{
  max-width: 100vw;
  max-height: 100vh;
  overflow-x: auto;
  overflow-y: auto;
  border: 1px solid #d9d9d9;
}
.table, .tableNoBorder {
  width: 100%;
  max-width: 100%;
}
.orderAdmin__table .table td {
  white-space: nowrap;
  background-color: #fff;
}
.orderAdmin__table .table td, .orderAdmin__table .table th {
  border: none;
  box-shadow: inset -1px -1px 0 #d9d9d9;
}
.table tr th {
  vertical-align: middle;
  background-color: #fafafa;
  text-align: left;
  font-size: 14px;
  color: #262626;
  border-top: 1px solid #d9d9d9;
  font-weight: 600;
}
.table td, .table th {
  padding: 10px 12px;
  border-bottom: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
}


.orderAdmin__table .table td:first-child, .orderAdmin__table .table th:first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 2;
}
.orderAdmin__table .table th {
  white-space: nowrap;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
}
.orderAdmin__table .table th:first-child {
  z-index: 3;
}