.dashboard{
  padding: 15px 0 115px 0;

  &__search{
    margin-bottom: 15px;
    @media (min-width: 768px) {
      width: 335px;
    }
  }
  &__menu{
    display: flex;
    justify-content: space-between;
    @media (min-width: 991px) {
      .w-100{
        width: 400px!important;
      }
    }
    .menu__item{
      width: calc(100%/2);
      border-bottom: 2px solid transparent;
      text-align: center;
      span{
        font-size: 14px;
        padding: 5px 15px;
        font-weight: 500;
        display: inline-block;
      }
      &.active{
        border-bottom: 2px solid #FA8C16;
        span{
          color: #FA8C16;
        }
      }
    }
  }
  &__content{
    padding-top: 15px;
    border-top: 4px solid #F5F5F5;
    @media (min-width: 768px) {
      display: flex;
      justify-content: space-between;
      border: none;
    }
    &__item{
      background: #FFFFFF;
      box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      padding: 15px;
      margin-bottom: 15px;
      @media (min-width: 768px) {
        width: calc(100%/3 - 10px);
      }
      .title{
        font-size: 16px;
        text-transform: uppercase;
        text-align: center;
        font-weight: 400;
        margin: 0;
      }
      p.price{
        font-size: 28px;
        font-weight: 600;
        width: 100%;
        margin-bottom: 10px;
        color: #262626;
        text-align: center;
        display: inline-block;
      }
      .ant-progress-bg{
        height: 16px!important;
      }
      .ant-progress-outer{
        padding-right: 0;
      }
      .ant-progress-text{
        display: none;
      }
      .text__dashboard{
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        p{
          color: #000000;
          margin-top: 0;
        }
        p.incurred{
          font-size: 14px;
          span{
            font-weight: 600;
            margin-left: 5px;
          }
        }
      }
    }
  }
  .hot__product{
    @media (min-width: 768px) {
      width: calc(33% - 8px);
      border-radius: 5px;
      order: 2;
      padding-top: 8px;
    }
    padding-bottom: 15px;
    border-bottom: 4px solid #F5F5F5;
    background: #ffffff;
    h2.title{
      font-size: 16px;
      font-weight: 600;
      color: #262626;
      text-transform: uppercase;
      text-align: center;
    }
    &__item{
      display: flex;
      align-items: center;
      padding: 15px 0;
      border-bottom: 1px solid #F5F5F5;
      span.stt{
        font-size: 16px;
        font-weight: 600;
        color: #000000;
        padding: 0 15px;
      }
      .images{
        width: 56px;
        height: 56px;
        border: 1px solid #E8E8E8;
        border-radius: 4px;
        margin-right: 8px;
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .text{
        p.title{
          font-size: 14px;
          font-weight: 600;
        }
        p.total__order{
          span{
            font-weight: 600;
          }
        }
      }
    }
  }
  .dashboard__report{
    margin-top: 15px;
    background: #ffffff;
    @media (min-width: 768px) {
      border-radius: 4px;
      margin-top: 0px;
      padding: 15px;
      order: 1;
      width: calc(67% - 8px);
    }
    canvas {
      width: 100% !important;
      height: 410px!important;
    }
  }
  .product__report{
    display: flex;
    justify-content: space-between;
    @media (max-width: 767px) {
      display: block;
    }
  }
  .top__publisher{
    @media (min-width: 992px) {
      display: none;
    }
    h2.title{
      font-size: 16px;
      font-weight: 600;
      color: #262626;
      text-transform: uppercase;
      text-align: center;
    }
    .list__publisher{
      .item__publisher{
        display: flex;
        justify-content: space-between;
        padding: 10px 0;
        .title{
          text-transform: uppercase;
          font-weight: 600;
          color: #8C8C8C;
        }
        .name__publisher{
          display: flex;
          width: 70%;
          .stt{
            width: 30px;
            font-weight: 600;
            margin-right: 10px;
            text-align: center;
          }
          .name{
            font-weight: 600;
          }
        }
        .commission{
          span{
            color: #1890FF;
            font-size: 14px;
            font-weight: 600;
          }
        }
      }
    }
  }
  .new__order{
    background: #ffffff;
    padding: 15px;
    min-height: 360px;
    margin-top: 15px;
    h2.title{
      font-size: 16px;
      font-weight: 600;
      color: #262626;
      text-transform: uppercase;
    }
    @media (max-width: 767px) {
      display: none;
    }
  }
}