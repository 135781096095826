a, button {
    background: 0 0;
    padding: 0
}

a, button, select {
    cursor: pointer
}

a, ins {
    text-decoration: none
}

body, button, input, select, textarea {
    font-family: Arial
}

@font-face {
    font-family: "Open Sans";
    src: url(https://statics.vntrip.vn/fonts/OpenSans/Regular/OpenSans-Regular.woff?v=1.101) format("woff");
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: "Open Sans";
    src: url(https://statics.vntrip.vn/fonts/OpenSans/Italic/OpenSans-Italic.woff?v=1.101) format("woff");
    font-weight: 400;
    font-style: italic
}

@font-face {
    font-family: "Open Sans";
    src: url(https://statics.vntrip.vn/fonts/OpenSans/SemiBold/OpenSans-SemiBold.woff?v=1.101) format("woff");
    font-weight: 600;
    font-style: normal
}

@font-face {
    font-family: "Open Sans";
    src: url(https://statics.vntrip.vn/fonts/OpenSans/Bold/OpenSans-Bold.woff?v=1.101) format("woff");
    font-weight: 700;
    font-style: normal
}

*, :after, :before {
    box-sizing: border-box
}

abbr, address, article, aside, audio, b, blockquote, body, canvas, caption, cite, code, dd, del, details, dfn, div, dl, dt, em, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, img, ins, kbd, legend, li, mark, menu, nav, object, ol, p, pre, q, samp, section, small, span, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, ul, var, video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: 0 0
}

label, p {
    margin-bottom: 5px
}

ins, mark {
    background-color: #ff9;
    color: #000
}

html {
    -webkit-tap-highlight-color: transparent
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block
}

ul {
    list-style: none
}

blockquote, q {
    quotes: none
}

blockquote:after, blockquote:before, q:after, q:before {
    content: ''
}

a {
    margin: 0;
    font-size: 100%;
    vertical-align: baseline;
    color: #1890FF
}

.btn, img {
    vertical-align: middle
}

a:focus, button:focus {
    outline: 0
}

label {
    display: inline-block
}

h1, h2, h3, h4, h5 {
    font-weight: 600
}

mark, strong {
    font-weight: 700
}

button {
    border: none
}

.btn, .btn.btn_sm {
    padding: 0 15px;
    font-size: 14px
}

textarea {
    resize: none
}

mark {
    font-style: italic
}

del {
    text-decoration: line-through
}

abbr[title], dfn[title] {
    border-bottom: 1px dotted;
    cursor: help
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

button, input, select, textarea {
    vertical-align: middle
}

body {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #262626;
    background-color: #fff;
    -webkit-font-smoothing: antialiased
}

@media (min-width: 768px) {
    p {
        margin-bottom: .5vw
    }

    body {
        font-size: 1vw
    }
}

h1 {
    font-size: 26px
}

@media (min-width: 768px) {
    h1 {
        font-size: 3vw
    }
}

h2 {
    font-size: 22px
}

@media (min-width: 768px) {
    h2 {
        font-size: 2vw
    }
}

h3 {
    font-size: 18px
}

@media (min-width: 768px) {
    h3 {
        font-size: 1.6vw
    }
}

h4 {
    font-size: 16px
}

@media (min-width: 768px) {
    h4 {
        font-size: 1.2vw
    }
}

h5 {
    font-size: 13px
}

@media (min-width: 768px) {
    h5 {
        font-size: .8vw
    }
}

.hidden {
    display: none !important
}

.show {
    display: block !important
}

.d-table {
    display: table !important
}

.d-cell {
    display: table-cell !important
}

.d-block {
    display: block !important
}

.d-inline {
    display: inline !important
}

.d-inline-block {
    display: inline-block !important
}

.d-flex {
    display: flex !important
}

.btn, .form-control {
    display: inline-flex
}

.text-left {
    text-align: left !important
}

.text-center {
    text-align: center !important
}

.text-right {
    text-align: right !important
}

.btn, .header__bell__count, .home-login, .register-content {
    text-align: center
}

.italic {
    font-style: italic !important
}

.regular {
    font-weight: 400 !important
}

.medium {
    font-weight: 500 !important
}

.semibold {
    font-weight: 600 !important
}

.bold {
    font-weight: 700 !important
}

.size-10 {
    font-size: 10px !important
}

.size-12 {
    font-size: 12px !important
}

@media (min-width: 768px) {
    .size-12 {
        font-size: .8vw !important
    }
}

.size-14 {
    font-size: 14px !important
}

.size-16 {
    font-size: 16px !important
}

.size-18 {
    font-size: 18px !important
}

.size-20 {
    font-size: 20px !important
}

.size-22 {
    font-size: 22px !important
}

.size-24 {
    font-size: 24px !important
}

.size-28 {
    font-size: 28px !important
}

.w-25 {
    width: 25% !important
}

.width-25 {
    width: 25px !important;
    transform: translateY(1px);
}

.w-50 {
    width: 50% !important
}

.w-75 {
    width: 75% !important
}

.w-100 {
    width: 100% !important
}

.h-25 {
    height: 25% !important
}

.h-50 {
    height: 50% !important
}

.h-75 {
    height: 75% !important
}

.h-100 {
    height: 100% !important
}

input::-webkit-input-placeholder {
    color: #BFBFBF
}

input::-moz-placeholder {
    color: #BFBFBF
}

input:-ms-input-placeholder {
    color: #BFBFBF
}

input:-moz-placeholder {
    color: #BFBFBF
}

.btn {
    white-space: nowrap;
    touch-action: manipulation;
    background: #1890FF;
    cursor: pointer;
    user-select: none;
    border-radius: 5px;
    color: #fff;
    transition: all .3s;
    height: 40px;
    border: 1px solid transparent;
    align-items: center;
    justify-content: center
}

.btn:hover {
    opacity: .8;
    color: #fff
}

.btn svg {
    margin-right: 5px
}

@media (min-width: 768px) and (max-width: 1199px) {
    .btn svg {
        width: 1vw
    }
}

.btn.btn_sign_in {
    background: #fff;
    border: 1px solid #BFBFBF;
    color: #262626;
    font-size: 14px
}

.btn.btn_outline {
    border-color: #1890FF;
    background: #fff;
    color: #1890FF
}

.btn.btn_sm {
    height: 32px
}

@media (min-width: 768px) {
    .btn svg {
        margin-right: .3vw
    }

    .btn.btn_sm {
        height: 2.3vw;
        padding: 0 1vw;
        font-size: 1vw
    }
}

.btn.btn_disabled {
    pointer-events: none;
    cursor: default;
    opacity: .5
}

.size-48 {
    height: 48px
}

.form-group {
    margin-bottom: 10px
}

.form-group label {
    font-weight: 700
}

.form-control {
    align-items: center;
    padding: 0 15px;
    font-size: 14px;
    color: #262626;
    border: 1px solid rgba(0, 0, 0, .15);
    background: #fff;
    border-radius: 3px;
    width: 100%;
    height: 48px;
    -webkit-appearance: none;
    -moz-appearance: none
}

.form-control:focus {
    outline: 0;
    box-shadow: 0 0 4px rgba(24, 144, 255, .5);
    border: 1px solid #40A9FF
}

textarea.form-control {
    height: 80px;
    padding: 5px 15px
}

@media (min-width: 768px) {
    .form-group {
        margin-bottom: .8vw
    }

    textarea.form-control {
        height: 7vw;
        padding: .5vw 1vw
    }
}

.form-control[disabled] {
    background-color: #f5f5f5 !important;
    cursor: not-allowed
}

.header {
    padding: 14px 0;
}

.header__btn {
    height: 20px
}

.header__bell {
    position: relative;
    transform: translateY(2px);
}

.header__bell__count {
    position: absolute;
    right: -1px;
    top: -3px;
    border-radius: 50%;
    background: #F5222D;
    font-size: 10px;
    color: #fff;
    width: 14px;
    height: 14px;
    line-height: 14px
}

.container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px
}

@media (min-width: 768px) {
    .container {
        width: 750px
    }
}

@media (min-width: 992px) {
    .container {
        width: 970px
    }
}

@media (min-width: 1200px) {
    .container {
        width: 1170px
    }
}

@media (max-width: 1440px) {
    .container {
        width: 100%!important;
    }
}

@media (min-width: 1500px) {
    .container {
        width: 1270px!important;
    }
}

.wrapper {
    min-height: 100%
}

.home-login .header-logo {
    margin-top: 24px
}

.home-login .header-logo img {
    object-fit: cover
}

.home-login .login-title {
    margin-top: 12px;
    text-transform: uppercase
}

.home-login .login-title h2 {
    font-weight: 500;
    font-size: 20px
}

.home-login .login-form {
    margin-top: 50px
}

.home-login .login-form .form-group {
    position: relative
}

.home-login .login-form .form-group .formBox__input input {
    padding: 0 30px
}

.home-login .login-form .form-group .input-icon svg {
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
    z-index: 1;
    width: 20px;
    height: 20px;
}


.home-login .login-form .form-password {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    font-size: 12px
}

.home__banner .home__box .box__commission, .home__banner .home__box .box__footer {
    justify-content: space-between;
    display: flex;

}
.home__banner .home__box .box__footer{
    border-top: 1px solid #D9D9D9;
}

.home-login .login-form .btn-sign-in {
    margin-top: 16px;
    font-size: 16px;
    font-weight: 400
}

.home-login .login-form .tip-login {
    margin-top: 16px;
    font-size: 14px
}

.home-login .login-form p.term {
    position: fixed;
    bottom: 15px;
    font-size: 12px;
    color: #595959;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%
}

.home-login .login-form p.term a {
    font-weight: 600
}

.home-login .login-form .login-line {
    margin: 16px 0;
    color: #595959
}

.header-logo {
    margin-top: 26px
}

.home__banner {
    position: relative;
}

.home__banner .home__box {
    position: relative;
    top: 0;
    background: #fff;
    z-index: 1;
    width: 100%;
    box-shadow: 1px 0 8px rgba(0, 0, 0, .15);
    border-radius: 5px
}

.home__banner .home__box .box__content {
    padding: 15px 15px 10px
}

.home__banner .home__box .box__content span.total__price {
    font-size: 28px;
    font-weight: 600
}

.home__banner .home__box .box__footer {
    padding: 10px 15px;
    font-size: 14px
}

.home__banner .home__box .box__footer p.price__incurred {
    color: #000
}

.home__banner .home__box .box__footer p.ratio, .home__product .item__product p {
    color: #262626
}

.home__banner .home__box .box__footer p.price__incurred span {
    margin-left: 5px;
    font-weight: 600
}

.home__banner .home__box .box__commission p.total__commission {
    color: #262626;
    font-size: 16px;
    text-transform: uppercase;
    margin-bottom: 0;
}

.home__list__options .list__options {
    /*display: flex;
    flex-wrap: wrap;*/
    display: block;
    justify-content: space-between;
}

.home__list__options .option__item {
    width: calc(100% / 3);
    text-align: center;
    margin: 13px 0;
    display: inline-block;
}

.home__list__options .option__item .option__icon {
    height: 72px;
    width: 72px;
    display: flex;
    align-items: center;
    margin-left: 50%;
    transform: translate(-50%, 0);
    justify-content: center;
    /*background: #389E0D;*/
    border-radius: 15px
}

.home__list__options .option__item .option__icon img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px
}

.home__list__options .option__item .option__icon.thoi-trang {
    background: #722ED1
}

.home__list__options .option__item .option__icon.my-pham {
    background: #EB2F96
}

.home__list__options .option__item .option__icon.thuc-pham {
    background: #CF1322
}

.home__list__options .option__item .option__icon.sinh-ly {
    background: #13C2C2
}

.home__list__options .option__item .option__icon.giam-can {
    background: #1890FF
}

.home__list__options .option__item p {
    margin-top: 10px;
    margin-bottom: 0;
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.home__marketing .item__marketing {
    width: 100%;
    height: 136px;
    padding-right: 12px
}

.home__marketing .item__marketing img {
    width: 100%;
    height: 100%;
    object-fit: cover
}

.home__product {
    display: flex;
    flex-wrap: wrap
}

.home__product .item__product {
    width: 50%;
    border-right: 1px solid #E8E8E8;
    border-bottom: 1px solid #E8E8E8;
    padding: 15px
}
.home__product .item__product img{
    width: 100%;
}

.home__product .item__product:nth-child(2n) {
    border-right: 1px solid transparent
}

.home__product .item__product p.title {
    width: 100%;
    white-space: pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box
}

.home__product .item__product p.chiet-khau {
    display: flex;
    align-items: center
}

.home__product .item__product p.chiet-khau svg {
    margin-left: 5px
}

.home__product .item__product span.price {
    font-weight: 600;
    font-size: 16px;
    color: #262626;
}

.home__product .item__product span.price__discount {
    color: #1890FF;
    font-weight: 600;

}
span.price__discount.in_search {
    color: #1890FF!important;
    font-weight: 600;
}

@media (max-width: 767px) {
    .slick-track {
        left: -25px !important
    }
}

.scroll-to-top {
    bottom: 85px;
    right: 22px;
    z-index: 99999;
    cursor: pointer;
    border: 0;
    text-decoration: none;
    position: fixed;
    opacity: 0.5;
}

@media (max-width: 767px) {
    .scroll-to-top {
        bottom: 85px;
        right: 22px;
    }
}
