.check__out {
  &__content {
    padding-bottom: 120px;
    &__desktop{
      display: flex;
      justify-content: space-between;
      @media (max-width: 991px) {
        display: block;
      }
    }
  }
  &__right{
    background: #ffffff;
    width: 40%;
    @media (max-width: 991px) {
      width: 100%;
    }

  }
  &__left{
    width: calc(60% - 15px);
    background: #ffffff;
    @media (max-width: 991px) {
      width: 100%;
    }
  }
  .item__order {
    .profile__booker__form{
      .form-group{
        .ant-form-item-explain, .ant-form-item-extra{
          min-height: 0;
        }
        .ant-form-item{
          margin-bottom: 15px;
        }
      }
      .form-group{
        label{
          font-size: 14px;
          font-weight: normal;
          color: #595959;

        }
      }
    }
    &.border__bottom{
      border-bottom: 3px solid #F5F5F5;
      @media (min-width: 991px) {
        border-bottom: 3px solid transparent;
      }
    }
    padding: 15px 0;
    h2.title{
      font-size: 16px;
      margin: 10px 0
    }
  }
  .container{
    @media (max-height: 991px) {
      padding: 0;
    }
  }

  &__item {
    padding: 15px;
    border-bottom: 3px solid #F5F5F5;

    &:last-child {
      border-bottom: 3px solid transparent;
    }

    .title {
      margin-bottom: 10px;

      h4 {
        font-weight: 600;
        font-size: 16px;
        color: #262626;
        text-transform: uppercase;
      }
    }
  }

  &__address {
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      .change__address {
        color: #1890FF;
        font-size: 14px;
        cursor: pointer;
      }

      h4 {
        font-weight: 600;
        font-size: 16px;
        color: #262626;
        text-transform: uppercase;
      }
    }

    .info__address {
      .name {
        font-weight: 600;
        font-size: 14px;
        color: #262626;
      }

      .address {
        font-size: 12px;
        color: #262626;
      }
    }
  }

  &__list {
    .item__product {
      display: flex;
      margin-bottom: 10px;

      .item__image {
        width: 72px;
        height: 72px;
        margin-right: 10px;

        img {
          width: 100%;
          height: 100%;
          aspect-ratio: 1/1;
          border-radius: 4px;
          object-fit: contain;
        }
      }

      .item__text {
        width: calc(100% - 85px);

        .name__product {
          font-size: 14px;
          font-weight: 600;
        }

        .price__product {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        p {
          font-size: 14px;
          color: #262626;
        }

        p.price {
          font-weight: 600;
        }
      }
    }
  }

  &__coupon {
    .form__input {
      display: flex;
      width: 100%;
    }

    .ant-form-item {
      width: 100%;
    }

    .input-group-btn {
      transform: translateX(-1px);
    }
  }

  .button__order{
    padding: 15px;
    width: 100%;
    text-align: right;
    button{
      width: 200px;
      margin-top: 20px;
    }
  }

  .form__btn {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
    background: #ffffff;
    padding: 15px;
    @media (min-width: 992px) {
      position: inherit;
      box-shadow: none;
    }

    .net__price {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;

      p, span {
        font-size: 14px;
        margin-bottom: 0;
      }

      span {
        color: #595959;
      }

      p.price {
        color: #F5222D;
        font-weight: 600;
      }
    }
  }

  .ant-form-item-has-success .ant-form-item-explain div {
    color: #91bb15;
  }
}

.box__address {
  background: #ffffff;
  transition: .4s;
  &.open{
    transform: translateX(0);
  }

  &__content {

  }
  .ant-radio-group{
    width: 100%;
  }

  &__item {
    padding: 15px;
    border-bottom: 3px solid #f5f5f5;
    display: flex;
  }

  .form-radio {
    white-space: inherit;
    display: flex;
    align-items: center;
    &.ant-radio-wrapper{
      margin-right: 0;
    }

  }
  .address__item{
    display: flex;
    justify-content: space-between;
    &__text{
      p{
        font-size: 14px;
        color: #262626;
      }
      p.info{
        font-weight: 600;
      }
    }
    &__icon{
      position: absolute;
      right: 15px;
      z-index: 9;
      .action{
        position: absolute;
        left: -80px;
        width: 80px;
        top: 0;
        text-align: right;
        z-index: 10;
        background: #ffffff;
        box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
        padding: 0 5px;
      }
    }
  }
  .add__address{
    span{
      color: #1890FF;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
    }
  }

  .ant-form-item-explain, .ant-form-item-extra{
    min-height: 0!important;
  }
}