$mobile-width: 576px;
$tablet-width: 768px;

@mixin mobile {
  @media (max-width: #{$mobile-width}) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: #{$tablet-width}) {
    @content;
  }
}
.list-top5-page {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    @include mobile {
        justify-content: flex-start;
        margin-left: 8px;
    }
    .itemCard {
        width: 167px;
        height: 163px;
        background: #ffffff;
        box-sizing: border-box;
        border-radius: 12px;
        margin-right: 16px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #5c6366;
        height: 100%;
        position: relative;
        .images {
            border-radius: 12px;
            width: 100%;
            height: 148px;
            border: 0!important;
            img {
                border-radius: 10px 10px 0 0;
                object-fit: contain;
            }
        }
        
        .text-group {
            padding: 8px;
            width: 100%;
            .title {
                display: -webkit-box;
                overflow: hidden;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }
        .comm {
            font-size: 12px;
            line-height: 18px;
            color: #0097e6;
        }
        .percent {
            span {
                margin-right: 6px;
                color: #ffffff;
                font-weight: 700;
                font-size: 10px;
                line-height: 15px;
            }
            background: #da4f25;
            border-radius: 16px;
            padding: 2px;
            position: absolute;
            top: 4px;
            left: 4px;
            justify-content: space-between;
            display: flex;
            align-items: center;
        }
        .salePrice {
            font-weight: 700;
            font-size: 13px;

            color: #3d3f40;
        }
        .originPrice {
            font-style: normal;
            font-size: 12px;

            text-decoration-line: line-through;
            color: #899296;
            margin-right: 8px;
        }
        .discount {
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
    }
}
.filter_label {
    display: flex;
    @include mobile {
        padding-left: 15px;
    }
    .label {
        border: 1px solid #899296;
        box-sizing: border-box;
        border-radius: 8px;
        font-size: 12px;
        padding: 4px 12px;
        margin-right: 4px;
        cursor: pointer;
    }
    .label.active {
        background: rgba(68, 189, 50, 0.2);
        border: 1px solid #44bd32;
        color: #44bd32;
    }
}
.hot__product {
    padding-left: 16px;
    padding-right: 16px;
    .title {
        @include mobile {
            padding-left: 15px;
        }
        h2 {
        padding-left: 0 !important;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        text-transform: uppercase!important;
        color: #3d3f40;
        margin-bottom: 12px;
        }
    }
}

.home .hot__product__item {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 0 !important;
    
}
