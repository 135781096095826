
.menu-mobile {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  visibility: hidden;

  &__backdrop {
    position: absolute;
    right: 0;
    background-color: rgba(0, 0, 0, .75);
    opacity: 0;
    visibility: hidden;
    transition: .4s ease-in-out;
    left: 0;
    bottom: 0;
    top: 0;
  }

  &__main {
    width: calc(100% - 90px);
    position: absolute;
    z-index: 1;
    visibility: hidden;
    transition: .4s ease-in-out;
    transform: translateX(-100%);
    left: 0;
    bottom: 0;
    background: #fff;
    top: 0;
  }
  &__header{
    padding: 15px;
    background: #F5F5F5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .menu__avatar{
      display: flex;
      align-items: center;
      .user__name{
        margin-left: 10px;
        p {
          font-size: 16px;
          color: #262626;
          font-weight: 500;
          margin-bottom: 0;
        }
        a{
          font-size: 12px;
        }
      }
    }
  }
  &__footer{
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    border-top: 4px solid #F5F5F5;
  }

  ul.ant-menu{
    height: 100%;
    width: 100% !important;
  }
}

.header {
  background-size: 100%;
  height: 160px;
  position: relative;
  &__info{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  .box__search {
    margin-bottom: 15px;
    &__content{
      display: flex;
      align-items: center;
      .back{
        display: none;
      }
      .header__bell{
        display: none;
        margin-top: 6px;
      }
      &.fix__top{
        padding: 10px;
        background: url("https://dimuadi.vn/_static/img/common/headerPublisher.png");
        background-size: 100%;
        .input__home__search{
          margin: 0 5px;
        }
      }
      &.fix__top__buyer{
        position: fixed;
        padding: 10px;
        background: url("https://dimuadi.vn/_static/img/common/headerBuyer.png");
        background-size: 100%;
        top: 0;
        right: 0;
        left: 0;
        z-index: 3;
        .input__home__search{
          margin: 0 5px;
        }
        .header__bell, .back{
          display: inline-block!important;
        }
      }
    }
  }

  @media (min-width: 991px) {
    display: none;
  }
  &__name{
    width: 100%;
    text-align: center;
    margin-left: 25px;
    &.not__login{
      margin-left: 0;
    }
    p{
      color: #ffffff;
    }
  }
  &.open-menu-mobile {
    .menu-mobile {
      visibility: visible;
      &__main {
        transform: translateX(0);
        visibility: visible;
      }
      &__backdrop{
        visibility: visible;
        opacity: 1
      }
    }
  }
  .header__bell{
    svg {
      width: 22px;
      height: 22px;
    }
  }
}
.menu__mobile{
  @media (min-width: 991px) {
    display: none;
  }
  position: fixed;
  background: #ffffff;
  bottom: 0;
  z-index: 3;
  width: 100%;
  box-shadow: 1px 0 8px rgba(0,0,0,.15);
  &__content{
    padding:10px 15px;
    .list__menu{
      display: flex;
      justify-content: space-between;
      .menu__item{
        text-align: center;
        p{
          margin: 0;
          font-size: 12px;
          color: #8C8C8C;
        }
        &.active{
          span{
            color: #1890FF!important;
          }
          p{
            color: #1890FF;
          }
        }
      }
      .menu__item__home{
        background: #1890FF;
        width: 40px;
        height: 40px;
        line-height: 48px;
        border-radius: 50%;
        span{
          svg{
            width: 25px;
            height: 25px;
          }
        }
      }
    }
  }
}

.header__desktop{
  position: relative;
  z-index: 10;
  background: #ffffff;
  height: 60px;
  @media (max-width: 991px) {
    display: none;
  }

  &__content{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  &__right, &__account{
    display: flex;
    align-items: center;
  }

  &__right{
    //padding: 0 15px;
    .header__bell{
      margin-right: 15px;
      margin-top: 5px;
    }
  }
  &__account{
    padding: 10px 0px;
    cursor: pointer;
    position: relative;
    &:after{
      content: "";
      color: #ccc;
      display: inline-block;
      margin-left: .255em;
      border-top: .3em solid;
      border-right: .3em solid transparent;
      border-bottom: 0;
      border-left: .3em solid transparent;
    }
    &.d-none-after{
      &:after{
        display: none;
      }
    }
    .user_name{
      margin-left: 10px;
      margin-right: 5px;
      p{
        margin-bottom: 0;
      }
    }
  }
  .account__dropdown{
    position: absolute;
    max-height: 200px;
    top: 60px;
    bottom: 0;
    right: 15px;
    width: 200px;
    height: 125px;
    background: #ffffff;
    transition: 0.3s;
    opacity: 0;
    visibility: hidden;
    display: block;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    &.open{
      visibility: visible;
      opacity: 1;
    }
    .item__account{
      padding: 10px;
      background: #ffffff;
      transition: 0.3s;
      cursor: pointer;
      &:hover{
        color: #1890FF;
        a{
          color: #1890FF;
        }
      }
      span.anticon{
        margin-right: 15px;
      }
      a{
        color: #262626;
      }
    }
  }

}
.header__desktop__buyer{
  background: #096DD9;
  color: #ffffff;
  @media (max-width: 767px) {
    display: none;
  }
}
.menu__sideBar{
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 20;
  box-shadow: 0 4px 12px rgba(0,0,0,.15);
  width: 256px;
  background-color: #fff;
  transition: all .4s ease;
  //overflow: hidden;
  @media (max-width: 991px) {
    display: none;
  }
  .sideBar__header{
    display: flex;
    align-items: center;
    //width: 256px;
    position:relative;
    height: 60px;
    padding: 10px 15px;
    justify-content: space-between;
    //box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    border-bottom: 3px solid #F5F5F5;
    .logo{
      width: 70px;
      //height: 40px;
      display: inline-block;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .icon__btn{
      position: absolute;
      cursor: pointer;
      top: 15px;
      //transform: translate(0, -50%);
      right: 10px;
      svg{
        width: 20px;
      }
    }
  }
  .sidebar__body{
    height: calc(100% - 110px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 20px;
    .sidebar__nav{
      &__item{
        &.active{
          > a{
            background: #fff7e6!important;
            color: #fd8c13!important;
            font-weight: 500!important;
          }
        }
        &.nav-sub{
          >.sidebar{
            &__nav{
              &__link{
                &:after{
                  //content: '\f078';
                  //font-family: "Font Awesome 5 Free";
                  font-weight: 900;
                  font-size: 12px;
                  position: absolute;
                  top: 11px;
                  right: 20px;
                }
              }
            }
          }
          &.open{
            >.sidebar{
              &__nav{
                &__child{
                  display: block;
                }
                &__link{
                  &:after{
                    transform: rotate(180deg);
                  }
                }
              }
            }
          }
        }
      }
      &__link{
        font-size: 14px;
        line-height: 22px;
        color: #595959;
        display: block;
        position: relative;
        padding: 10px 25px;
        transition: all .2s;
        @media (max-width: 991px) {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &:hover{
          background: #fafafa;
          color: #595959;
        }
      }
      &__child{
        display: none;
      }
      &__tooltip{
        position: fixed;
        left: 86px;
        background-color: rgba(0,0,0,.8);
        color: #fff;
        border-radius: 4px;
        padding: 5px 15px;
        transform: translateY(-35px);
        transition: all .4s ease;
        opacity: 0;
        visibility: hidden;
        p{
          color: #fff;
          margin: 0;
        }
        &:before{
          content: "";
          border: 5px solid transparent;
          border-right-color: rgba(0,0,0,.8);
          position: absolute;
          top: 50%;
          left: -10px;
          transform: translateY(-50%);
        }
      }
      .sidebar__nav__item.active>a {
        background: #fff7e6!important;
        color: #fd8c13!important;
        font-weight: 500!important;
      }
      &__list {
        >li.nav-sub.open .sidebar__nav__child{
          display: block;
        }
        >li {
          >a{
            font-size: 14px;
            color: #595959;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            padding: 0 25px;
            height: 40px;
            transition: all .1s;
            .text{
              padding-left: 15px;
              transition: all .4s ease;
              flex: 1 1;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
      height: calc(100% - 96px);
      overflow-y: auto;
      overflow-x: hidden;
    }
    .sidebar__contact{
      border-top: 2px solid #f5f5f5;
      padding: 15px 25px 0px 25px;
      .d-flex{
        display: flex;
        height: 40px;
        position: relative;
        align-items: center;
        span{
          color: #595959;
        }
        svg{
          margin-right: 15px;
        }
        p{
          transition: all .4s ease;
          margin: 0;
          position: absolute;
          top: 8px;
          left: 20px;
        }
      }
    }
  }
  &.collapsed{
    .sideBar__header{
      width: 80px;
      .logo{
        width: 45px;
      }
      .icon__btn{
        right: -5px;
        transform: rotate(180deg);
        svg{
          width: 20px;
        }
      }
    }
    .sidebar__nav__link{
      .down{
        display: none;
      }
    }
    width: 80px;
    .sidebar__nav__list>li>a>.text {
      font-size: 0;
      display: none;
    }
    .sidebar__nav__list>li:hover .sidebar__nav__tooltip {
      opacity: 1;
      visibility: visible;
    }
    .sidebar__nav__list>li>a:hover {
      background: #fafafa;
      color: #595959;
    }
    .sidebar__nav__child {
      position: fixed;
      z-index: 50;
      background-color: #fff;
      box-shadow: 0 2px 8px rgba(0,0,0,.15);
      left: 80px;
      transform: translateY(-40px);
      width: 200px;
      transition: 0.4s;
    }
    .sidebar__contact{
      .d-flex{
        justify-content: center;
      }
      p{
        opacity: 0;
        visibility: hidden;
      }
    }
  }
}
