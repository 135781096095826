.box__backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: .4s ease-in-out;
  background: #000000;
  visibility: hidden;
  opacity: 0;
  z-index: 99;
}

.box__share {
  &.open {
    .box__share__content {
      transform: translateY(0);
      visibility: visible;
      @media (min-width: 768px) {
        transform: translate(-50%, 0%) scale(1);
        opacity: 1;
      }
    }

    .box__backdrop {
      visibility: visible;
      opacity: 0.8;
    }
  }
  @media (min-width: 768px) {
    .box__backdrop{
      background: rgba(0,0,0,.45);
    }
  }

  &__content {
    transform: translateY(100%);
    background: #fff;
    transition: .4s ease-in-out;
    padding: 15px;
    border-radius: 8px 8px 0 0;
    position: fixed;
    text-align: left;
    bottom: 0;
    left: 0;
    right: 0;
    max-height: 85%;
    overflow: scroll;
    visibility: hidden;
    z-index: 100;

    @media (min-width: 768px) {
      top: 20%;
      width: 500px;
      left: 50%;
      height: 350px;
      opacity: 0;
      transform: translate(-50%, 0%) scale(0);
    }

    .box__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;

      .box__close {
        color: #BFBFBF;
        font-size: 20px;
      }

      h2 {
        color: #262626;
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        margin: 0;

      }
    }

    .text__content {
      h2 {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 10px;
      }

      ul {
        list-style: inherit;
        color: #BFBFBF;
        padding-left: 15px;

        li {
          color: #595959;
          margin-bottom: 10px;
        }
      }
    }

    .share__video {
      margin-top: 20px;
      border-radius: 4px;
      overflow: hidden;
      height: 180px;
    }
  }
}

.box__share__main {
  .item__content {
    .content__title {
      font-size: 14px;
      color: #000000;
      text-transform: uppercase;
      margin-bottom: 10px;
    }

    .content__main {
      padding: 25px 15px;
      height: 200px;
      overflow: scroll;
      border: 1px solid #262626;
      border-radius: 5px;
      font-size: 12px;
      color: #595959;

      h3 {
        font-size: 12px;
        text-transform: uppercase;
        color: #595959;
        font-weight: 400;

      }
    }

    .group__btn {
      margin-top: 10px;
      display: flex;

      .btn-copy {
        margin-right: 10px;
      }
    }
  }

  .payment__share {
    display: flex;
    justify-content: space-between;

    .item__share {
      text-align: center;
      cursor: pointer;

      span {
        display: block;
      }
    }
  }

  .share__with__link {
    margin-top: 25px;
    text-align: center;

    h3 {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 12px;

    }

    .input__copy {
      display: flex;

      button.btn-copy {
        transform: translateX(-2px);
        background: #E8E8E8;
      }
    }
  }

}

.box__info__order {
  &__main {
    .box__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 10px 0;

      h3 {
        font-size: 14px;
        text-transform: uppercase;
      }
    }

    .order-item {
      padding: 10px 0;
    }

    .order__info {
      &__item {
        p {
          font-size: 14px;
          color: #262626;

          span {
            color: #8C8C8C;
          }

          span.success {
            color: #52C41A;
          }

          span.error {
            color: #F5222D;
          }
        }
      }
    }

    .product__info {
      &__item {
        display: flex;

        .item__image {
          width: 72px;
          margin-right: 10px;

          img {
            width: 100%;
            object-fit: cover;
          }
        }

        .item__text {
          width: calc(100% - 85px);

          .name {
            font-weight: 600;
          }

          .item__price, .item__quantity {
            display: flex;
            justify-content: space-between;

            p.price {
              font-weight: 600;
            }
          }

          .item__quantity {
            span {
              color: #595959;
            }
          }
        }
      }
    }

    .order__commission {
      p.text {
        align-items: center;
        display: flex;

        svg {
          margin-right: 5px;
        }
      }

      p.price {
        color: #52C41A;
        font-weight: 600;
      }

      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .form__btn {
      padding: 15px 0;
      width: 100%;
      //box-shadow: 1px 0 8px rgba(0, 0, 0, .15);
    }
  }

  &.open {
    .box__info__order__content {
      transform: translateY(0);
      visibility: visible;
    }

    .box__backdrop {
      opacity: 0.8;
      visibility: visible;
    }
  }
}

.box__filter, .box__info__order {
  &__content {
    transform: translateY(100%);
    background: #fff;
    transition: .4s ease-in-out;
    border-radius: 8px 8px 0 0;
    padding: 0 15px;
    position: fixed;
    text-align: left;
    overflow: scroll;
    bottom: 0;
    left: 0;
    max-height: 85%;
    right: 0;
    visibility: hidden;
    z-index: 100;
  }
}

.box__filter {
  &__main {
    .box__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 15px 0;

      h3 {
        font-size: 14px;
        text-transform: uppercase;
      }
    }

    label {
      margin-bottom: 10px;
    }
    .clear__filter{
      display: flex;
      justify-content: space-between;
      align-items: center;
      //margin-bottom: 10px;
    }

    .list__button {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .item__filter {
        margin-bottom: 15px;
        width: calc(100% / 3 - 10px);
        .ant-btn:hover, .ant-btn:focus {
          color: #262626;
          border-color: #d9d9d9;
        }

        &.active {
          button {
            border-color: #1890FF !important;
            width: 100%;
          }

          .icon_check, .check {
            display: inline-block;
          }
        }
      }

      button {
        width: 100%;

        &.active {
          border: 1px solid #1890FF;
        }
      }

      .ant-btn {
        &.active {
          border: 1px solid #1890FF;
        }
      }
    }
  }

  &.open {
    .box__filter__content {
      transform: translateY(0);
      visibility: visible;
    }

    .box__backdrop {
      opacity: 0.8;
      visibility: visible;
    }
  }
}

.box_search {
  &__content {
    position: fixed;
    transform: translateY(100%);
    background: #fff;
    transition: .4s ease-in-out;
    text-align: left;
    overflow: scroll;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    visibility: hidden;
    z-index: 100;
  }

  &.open {
    .box_search__content {
      transform: translateY(0);
      visibility: visible;
    }
  }

  &__main {
    .form__input__search {
      display: flex;
      padding: 10px 15px;
      align-items: center;

      .back__page {
        margin-right: 5px;
      }

      input, .ant-input-affix-wrapper {
        border: none;
        box-shadow: none;

        &:focus {
          outline: none;
          border: none;
          box-shadow: none;
        }
      }
    }

    .keyword__hot {
      .list__key__hot {
        display: flex;
        flex-wrap: wrap;

        .key__item {
          margin-right: 5px;
          margin-bottom: 5px;

          button {
            background: #E5E5E5;
            border: none;
          }
        }
      }
    }

    .box_search__item {
      padding: 15px;
      z-index: 10000;

      .title {
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;

        .delete {
          color: #1890FF;
        }
      }
    }
    .product__content{
      padding-bottom: 10px;
      .product__image{
        border: 1px solid #d9d9d9;
        border-radius: 4px;
      }
    }

    .search-near {
      &__item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        span {
          color: #262626;
        }

        span.icon .anticon {
          color: #8C8C8C;
        }
      }
    }
  }
}

.box__edit__delete {
  &__content {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    transition: 0.4s;
    transform: translateY(100%);
    padding: 15px;

    .action__item {
      margin-bottom: 10px;
      border-radius: 5px;
      background: #fff;

      button {
        height: 56px;
        font-weight: 600;
        border-bottom: 1px solid #d9d9d9;

        span {
          font-size: 18px;
        }
      }

      &.edit {
        button {
          span {
            color: rgb(27, 168, 255);
          }
        }
      }

      &.delete {
        button {
          span {
            color: rgb(255, 66, 78);
          }
        }
      }
    }
  }

  &.open {
    .box__edit__delete__content {
      transform: translateY(0);

    }

    .box__backdrop {
      visibility: visible;
      opacity: 0.8;
    }
  }
}

.box__confirm__cart {
  &__content {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    transition: .4s ease-in-out;
    padding: 15px;
    border-radius: 8px 8px 0 0;
    background: #ffffff;
    z-index: 100;
    transform: translateY(100%);
    visibility: hidden;
  }

  &__main {
    .title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      h2{
        font-size: 16px;
        font-weight: 600;
      }
      p {
        margin-bottom: 0;
        display: flex;
        align-items: inherit;
        color: rgb(13, 83, 2);
        font-size: 13px;

        &.red {
          color: #F5222D;
        }

        svg {
          margin-right: 10px;
          width: 20px;
          transform: translateY(2px);
        }

        span {
          width: calc(100% - 20px);
        }
      }
    }
    .btn__close {
      @media (min-width: 768px) {
        display: none;
      }
    }

    .product__info {
      display: flex;
      margin-bottom: 15px;

      .product__image {
        flex-basis: 25%;
        flex-shrink: 0;
        width: auto;
        height: auto;
        margin-right: 10px;

        img {
          object-fit: contain;
          aspect-ratio: 1;
          width: 100%;
          height: 100%;
        }
      }

      .product__text {
        .product__name {
          font-weight: 600;
        }

        .price {
          font-size: 16px;
          font-weight: 600;
        }

        .price__commission {
          color: #1890FF;
          font-weight: 500;
          font-size: 14px;
          display: flex;
          align-items: center;

          svg {
            margin-right: 5px;
          }
        }
      }
    }
    .list__color, .list__size{
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 15px;
    }
    .color__item, .size__item{
      &.active{
        button{
          color: #FA8C16;
          border: 1px solid #FA8C16;
          &:focus{
            box-shadow: none;
            outline: none;
          }
        }
      }
    }
  }

  &.open {
    .box__confirm__cart__content {
      transform: translateY(0);
      visibility: visible;
    }

    .box__backdrop {
      visibility: visible;
      opacity: 0.8;
    }
  }
}

.box__listContent {
  &__content {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    //overflow: scroll;
    transform: translateY(100%);
    visibility: hidden;
    max-height: 80%;
    transition: .4s ease-in-out;
    padding: 15px;
    border-radius: 8px 8px 0 0;
    background: #ffffff;
    z-index: 100;

    .box__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;

      h2 {
        color: #262626;
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        margin: 0;
      }

      .box__close {
        color: #bfbfbf;
        font-size: 20px;
      }
    }
  }

  &__main {
    padding-bottom: 80px;

    .list__content {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 15px;

      .item__button__content {
        margin-right: 10px;
        margin-bottom: 10px;
        &.active{
          button{
            border: 1px solid #1890FF;
            color: #1890FF;
          }
        }
      }
    }

    .content {
      overflow-y: scroll;
      height: 400px;
      transition: 0.4s;
      margin-bottom: 15px;
      padding-bottom: 80px;
    }
    @media (min-width: 768px) {
      .content{
        height: 300px;
        padding: 15px;
        border: 1px solid #d9d9d9;
      }
    }

    .see__more {
      margin-bottom: 15px;
    }

    .btn__group {
      position: fixed;
      bottom: 0;
      width: 100%;
      left: 0;
      z-index: 101;
      padding: 15px;
      background: #fff;
      @media (min-width: 768px) {
        position: inherit;
        text-align: right;
        padding: 0;
        button{
          width: 200px;
          margin-top: 15px;
        }
      }
    }
  }

  &.open {
    .box__backdrop {
      visibility: visible;
      opacity: 0.8;
    }
    .box__listContent__content{
      visibility: visible;
      opacity: 1;
      transform: inherit;
    }
  }
}

.box__confirm__tutorial{
  &__content{
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    //overflow: scroll;
    transform: translateY(100%);
    visibility: hidden;
    max-height: 80%;
    transition: .4s ease-in-out;
    padding: 15px;
    border-radius: 8px 8px 0 0;
    background: #ffffff;
    z-index: 100;
  }
  .box__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    h2 {
      color: #262626;
      font-size: 16px;
      font-weight: 600;
      text-transform: uppercase;
      margin: 0;
    }

    .box__close {
      color: #bfbfbf;
      font-size: 20px;
    }
  }
  .ant-checkbox-wrapper{
    margin-bottom: 15px;
  }
  p{
    margin-bottom: 15px;
  }
  &.open {
    .box__backdrop {
      visibility: visible;
      opacity: 0.8;
    }
    .box__confirm__tutorial__content{
      visibility: visible;
      opacity: 1;
      transform: inherit;
    }
  }
}

// datepicker mobile
@media (max-width: 767px) {
  .ant-picker-active-bar {
    display: none
  }

  .ant-picker-dropdown {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    padding: 0
  }

  .ant-picker-range-wrapper {
    min-width: initial !important;
    width: 100%;
    height: 100%
  }

  .ant-picker-range-arrow {
    display: none !important
  }

  .ant-picker-panel-container {
    box-shadow: none;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap
  }

  .ant-picker-panel-container .ant-picker-panels, .ant-picker-panel-container > .ant-picker-panel > .ant-picker-date-panel {
    height: calc(100% - 50px);
    order: 2;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch
  }

  .ant-picker-panel-container .ant-picker-panels, .ant-picker-panel-container > .ant-picker-panel {
    flex-wrap: wrap
  }

  .ant-picker-panel-container .ant-picker-footer {
    order: 1;
    height: 50px
  }

  .ant-picker-panel-container .ant-picker-footer .ant-picker-footer-extra {
    padding: 0
  }

  .ant-picker-date-panel, .ant-picker-date-panel .ant-picker-content, .ant-picker-decade-panel, .ant-picker-month-panel, .ant-picker-panel, .ant-picker-quarter-panel, .ant-picker-time-panel, .ant-picker-week-panel, .ant-picker-year-panel {
    width: 100%
  }

  .ant-picker-cell .ant-picker-cell-inner {
    min-width: 36px;
    height: 36px;
    line-height: 36px
  }

  .ant-picker-cell::before {
    height: 36px
  }

  .ant-picker-cell {
    padding: 5px 0
  }

  .ant-picker-large .ant-picker-input > input {
    font-size: 15px
  }
  .ant-picker-footer {
    .headerPopup {
      background-color: #1890ff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 15px;
      height: 50px;

      > p {
        font-weight: 600;
        color: #fff;
        margin-bottom: 0;
      }

      &__close {
        padding: 10px 0 10px 15px;

        svg {
          display: block;
        }
      }
    }
  }
}

.ant-popconfirm {
  width: 250px;
}
