.list__order{
  padding-bottom: 100px;
  border-radius: 5px;

  .header__order{
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    align-items: center;
    h2{
      font-size: 20px;
      font-weight: 500;
      margin: 0;
    }
  }

  &__desktop{
    padding: 15px;
    background: #ffffff;

    .table td{
      font-size: 14px;
      color: #595959;
    }
    .error__product{
      padding: 20px 0;
    }
    .filter__order__desktop{
      margin-bottom: 10px;
    }
    .filter__search__desktop{
      padding: 15px 0;
      display: flex;
      justify-content: space-between;
      .form__input{
        margin: 0;
        width: 60%;
      }
      .vntDatepicker{
        width: 30%;
      }
    }
    .filter__status__desktop{
      display: flex;
      border-bottom: 1px solid #d9d9d9;
      .status__item{
        padding: 10px 20px;
        font-size: 14px;
        color: #595959;
        cursor: pointer;
        &.active{
          border-bottom: 2px solid #1890FF;
          color: #1890FF;
          font-weight: 600;
        }
      }
    }
    .item__status{
      border-radius: 4px;
      border: 1px solid #d9d9d9;
      font-size: 12px;
      padding: 2px 6px;
      color: #d9d9d9;
      &.success,  &.approved{
        color:#52C41A;
        border: 1px solid #52C41A;
        background: #F6FFED;
      }
      &.fail, &.rejected{
        color:#F5222D;
        border: 1px solid #F5222D;
        background: #FFF1F0;
      }
      &.pending, &.pre_approved{
        color:#ff8916;
        border: 1px solid #ff8916;
      }
      &.hold{
        color:#899296;
        border: 1px solid #899296;
      }
    }
  }
  &__item{
    &.border__bottom{
      border-bottom: 4px solid #F5F5F5;
    }
    .list-order {
      .order__item{

        padding: 8px 15px;
        border-bottom: 1px solid #D9D9D9;
        .image__no__order{
          text-align: center;
          img{
            width: 140px;
            height: 100%;
            object-fit: cover;
          }
          p{
            color: rgba(0,0,0,0.54);
          }
        }
        .order__status, .order__commission{
          display: flex;
          justify-content: space-between;
        }
        p {
          font-size: 12px;
        }
        .order__status{
          p {
            color: #8C8C8C;
            font-weight: 500;
            span {
              color: #1890FF;
            }
          }
          p.success{
            color: #52C41A;
          }
          p.error{
            color: #F5222D;
          }
          p.warning{
            color: #faad14;
          }
          .order__code{
            width: 70%;
          }
        }
        .order__commission {
          p {
            color: #595959;
            font-weight: 600;
          }
          .commission{
            display: flex;
            align-items: center;
            svg{
              margin-right: 5px;
            }
          }
        }
      }
    }
  }

  .form__input{
    margin: 15px 0;
  }
  &__filter{
    &__content{
      display: flex;
      justify-content: space-between;
      padding: 10px 0 ;
      .filter__item{
        cursor: pointer;
        padding: 0;
        svg{
          margin-right: 5px;
        }
      }
    }
  }
  .pagination {
    &__content{
      margin-top: 20px;
    }
  }
}
.order__detail{
  @media (max-width: 991px) {
    .container{
      padding: 0;
    }
  }
  &__content{
    background: #ffffff;
    padding: 15px;
  }
  padding-bottom: 110px;
  .border__bottom{
    @media (min-width: 991px) {
      border: none;
    }
  }
  .box__title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0;
    h3{
      font-size: 14px;
      text-transform: uppercase;
    }
  }
  .order-item {
    padding: 10px 0;
  }
  .order__info {
    &__item{
      &.font-weight{
        font-weight: 600;
      }
      p{
        font-size: 14px;
        color: #262626;
        span{
          color: #8C8C8C;
          font-weight: 400;
        }
        span.success{
          color: #52C41A;
          font-weight: 600;
        }
        span.error{
          color: #F5222D;
          font-weight: 600;
        }
        span.processing{
          color: #FA8C16;
          font-weight: 600;
        }
        span.pending{
          color: #FA8C16;
          font-weight: 600;
        }
      }
    }
  }
  .product__info {
    &__item{
      display: flex;
      .item__image{
        width: 72px;
        margin-right: 10px;
        height: 72px;
        img {
          width: 100%;
          object-fit: cover;
          height: 100%;
          aspect-ratio:1/1;
          border-radius: 5px;
        }
      }
      .item__text {
        width: calc(100% - 85px);
        .name{
          font-weight: 600;
        }
        .item__price, .item__quantity {
          display: flex;
          justify-content: space-between;
          p.price{
            font-weight: 600;
          }
        }
        .item__quantity{
          span{
            color: #595959;
          }
        }
      }
    }
  }
  .order__commission {
    p.text{
      align-items: center;
      display: flex;
      svg{
        margin-right: 5px;
      }
    }
    p.price{
      color: #52C41A;
      font-weight: 600;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .form__btn{
    padding: 15px 0;
    width: 100%;
    //box-shadow: 1px 0 8px rgba(0, 0, 0, .15);
  }
  .table td{
    font-size: 14px;
    .status{
      width: 100%;
      span.error{
        color: #F5222D;
        border: 1px solid #F5222D;
        border-radius: 5px;
        padding: 3px 10px;
        text-align: center;
      }
      span.success{
        color: #52C41A;
        border: 1px solid #52C41A;
        border-radius: 5px;
        padding: 3px 10px;
        text-align: center;
      }

      span.pending{
        color: #ff8916;
        border: 1px solid #ff8916;
        border-radius: 5px;
        padding: 3px 10px;
        text-align: center;
      }

      span.processing{
        color: #ff8916;
        border: 1px solid #ff8916;
        border-radius: 5px;
        padding: 3px 10px;
        text-align: center;
      }

    }
  }

}
.variant__group {
  span {
    font-size: 13px;
    color: #595959;
    font-weight: 400;
    text-transform: capitalize;
  }
}
