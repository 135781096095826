.page__success {
  &__content{
    background: #ffffff;
    margin-top: 100px;
    text-align: center;
    @media (min-width: 991px) {
      margin-top: 0;
      padding-bottom: 50px;
    }
    h2{
      font-size: 20px;
      color: #262626;
      margin-bottom: 15px;
    }
    p{
      font-size: 14px;
      color: #595959;
    }
    .text {
      margin-bottom: 30px;
    }
    button{
      width: 210px;
    }
    button.btn__orange{
      background: #FA8C16;
      color: #ffffff;
    }
    p.or{
      margin: 15px 0;
    }
  }
}