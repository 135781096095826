.search__product__content{
  margin-top: 15px;
  padding-bottom: 180px;
  .ant-form-item-explain, .ant-form-item-extra{
    min-height: 0;
  }
  .home__list__options{
    .title{
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 15px;
      @media (max-width: 991px) {
        display: none;
      }
    }
    .list__options{
      background: #ffffff;
      .option__item{
        @media (min-width: 992px) {
          width: calc(100% / 5);
        }
      }
    }
  }
  .input__search{
    @media (min-width: 992px) {
      display: none;
    }
    transition: 0.4s;
    padding: 10px;
    display: flex;
    align-items: center;
    input{
      width: 100%;
    }
    .ant-form-item{
      margin-bottom: 0;
      margin-left: 5px;
      margin-right: 5px;
      width: 100%;
    }
    .header__bell, .back{
      display: none;
    }
    .header__bell{
      margin-top: 6px;
    }
  }
  .fix__top{
    position: fixed;
    background: #FA8C16;
    top: 0;
    right: 0;
    left: 0;
    z-index: 3;
    .header__bell, .back{
      display: inline-block;
    }
  }
  .search__product__item{
    padding: 15px 0;
    h2.title{
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 15px;
    }
    border-bottom: 4px solid #F5F5F5;
  }
  .banner{
    .banner__product{
      height: 136px;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 5px;
      }
    }
  }
  .filter__product{
    @media (min-width: 768px) {
      display: none;
    }
  }
  .share__near {
    &__item {
      padding: 12px 0;
      border-bottom: 1px solid #F5F5F5;
      border-bottom: 1px solid #F5F5F5;
      &:last-child {
        border-bottom: 1px solid transparent;

      }
      .btn_choose_blue{
        float: right;
      }
    }
    .product__content{
      .product__detail{
        .price__product{
          justify-content: unset;
          .price{
            margin-left: 10px;
          }
        }
      }
    }

  }
  .hot__directory{
    .list__directory{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      @media (min-width: 768px) {
        justify-content: inherit;
      }
      .directory__item{
        width: calc(100%/3 - 8px);
        text-align: center;
        padding: 10px;
        background: #F5F5F5;
        border-radius: 5px;
        margin-bottom: 10px;
        .icon{
          //width: 100%;
          height: 40px;
          width: 40px;
          margin: 5px auto;
          img{
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        p{
          font-size: 12px;
          margin-bottom: 0;
          white-space: pre-wrap;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          display: -webkit-box;
        }
        @media (min-width: 768px) {
          width: 128px;
          background: #fff;
          margin-right: 15px;
          height: 128px;
          border-radius: 3px;
          padding: 15px 10px;
          .icon{
            width: 48px;
            height: 48px;
            margin: auto;
          }
        }
      }
    }
    .see__more{
      display: flex;
      justify-content: center;
      align-items: center;
      @media (min-width: 768px) {
        display: none;
      }
      p{
        margin: 0 5px 0 0;

      }
    }
    .box__backdrop{
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      transition: .4s ease-in-out;
      background: #000000;
      visibility: hidden;
      opacity: 0;
      z-index: 99;
    }
    .box__all__directory{
      h2{
        font-size: 16px;
        font-weight: 600;
        margin: 0;
        padding: 15px 0 20px 0;
      }
      .box__all__content{
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 8px 8px 0px 0px;
        z-index: 100;
        transform: translateY(100%);
        transition: all .3s ease-in-out;
        visibility: hidden;
        background: #fff;
        overflow: scroll;
        max-height: 80%;
        padding: 15px;
      }
    }
    &.open{
      .box__backdrop{
        visibility: visible;
        opacity: 0.8;
      }
      .box__all__directory{
        visibility: visible;
        .box__all__content{
          transform: translateY(0);
          visibility: visible;
        }
      }
    }
  }
  .filter__directory{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .sort{
      display: flex;
      align-items: center;
      &__item{
        display: flex;
        margin-left: 5px;
        align-items: center;
        font-size: 14px;
        span{
          color: #8C8C8C;
        }
        p{
          margin-bottom: 0;
          margin-right: 5px;
        }
      }
    }
    .filter{
      span{
        margin-right: 5px;
      }
    }
  }
  .hot__week{
    @media (min-width: 768px) {
      display: none;
    }
  }
  .home__product__content{
    background: #ffffff;
    .header__title {
      h2{
        text-transform: uppercase;
      }
    }
    @media (min-width: 992px) {
      .item__product{
        width: 20%;
        border: none;
        a{
          display: flex;
          &.choose-product{
            display: block;
          }
        }
        .item__image{
          width: auto;
        }
        img{
          width: 112px;
          height: 112px;
          object-fit: cover;
          border: 1px solid #E8E8E8;
          margin-right: 10px;
          border-radius: 4px;
        }
      }
    }
  }
  .search__product__desktop{
    position: relative;
    .suggestDefault{
      width: 50%;
    }
  }
  @media (max-width: 991px) {
    .container{
      padding: 0;
    }
  }
}
.search-product{
  @media (min-width: 992px) {
    .box__header__title{
      display: none;
    }
  }
  .input__search__desktop{
    @media (max-width: 991px) {
      display: none;
    }
    display: flex;
    .ant-form-item{
      width: 50%;
      margin-right: 15px;
    }
    button{
      border-radius: 4px;
      padding: 0 15px;
    }
  }

}
.hot__week{
  .list__hot__week{

  }
  &__item{
    position: relative;
    border-radius: 4px;
    background: #fff;
    //margin: 4px;
    //padding: 5px;
    width: 98px;
    height: 98px;
    padding-right: 5px;
    display: inline-block;
    //border: 1px solid #f5f5f5;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .chiet-khau{
      padding: 0 6px;
      border: 1px solid #1890FF;
      background: #FFFFFF;
      border-radius: 4px;
      width: 80px;
      position: absolute;
      bottom: 0;
      right: 8px;
      display: flex;
      align-items: center;
      span{
        display: inline-block;
        margin-left: 5px;
        font-size: 12px;
        font-weight: 500;
      }
    }
    .discount{
      position: absolute;
      top: 0;
      left: 0;
      background: #F5222D;
      font-size: 12px;
      color: #fff;
      border-radius: 4px 0;
      padding: 1px 2px;
    }
  }
}
.box__filter__product{
  &__content{
    transform: translateX(100%);
    width: calc(100% - 50px);
    background: #fff;
    transition: .4s ease-in-out;
    position: fixed;
    text-align: left;
    overflow: scroll;
    bottom: 0;
    top: 0;
    //max-height: 85%;
    right: 0;
    visibility: hidden;
    z-index: 100;
  }
  &__main{
    .box__header{
      display: flex;
      justify-content: space-between;
      padding: 15px;
      background: #F5F5F5;
      color: #262626;
      span{
        color: #595959;
      }
    }
    .filter__item{
      padding: 15px 0;
      .title{
        font-weight: 600;
        font-size: 14px;
      }
      .list__filter{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .ant-checkbox-wrapper + .ant-checkbox-wrapper{
          margin-left: 0;
        }
        .item__filter{
          width: calc(50% - 5px);
          margin-bottom: 10px;
          position: relative;
          .ant-btn:hover, .ant-btn:focus{
            color: #262626;
            border-color: #d9d9d9;
          }
          button{
            width: 100%;
            border-radius: 5px;
            padding: 0;
            label{
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-bottom: 0;
            }
          }
          .icon_check, .check{
            position: absolute;
            right: 0;
            top: -2px;
            display: none;
          }
          .check{
            z-index: 13;
            top: 4px;
            right: 2px;
          }
          &.active{
            button{
              border-color: #1890FF!important;
            }
            .icon_check, .check{
              display: inline-block;
            }
          }
        }
      }
    }
    .form__btn{
      position: fixed;
      bottom: 0;
      left: 0;
      padding: 15px;
      right: 0;
    }
  }
  &.open{
    .box__backdrop{
      visibility: visible;
      opacity: 0.8;
    }
    .box__filter__product__content{
      transform: translateX(0);
      visibility: visible;
    }
  }
}
.box__sort__product{
  &__content{
    transform: translateY(100%);
    width: 100%;
    background: #fff;
    transition: .4s ease-in-out;
    border-radius: 8px 8px 0px 0px;
    position: fixed;
    text-align: left;
    overflow: scroll;
    bottom: 0;
    max-height: 85%;
    right: 0;
    visibility: hidden;
    z-index: 100;
  }
  &__main{
    padding: 0 15px ;
    .list__sort{
      .sort__item{
        border-bottom: 1px solid #D9D9D9;
        padding: 15px 0;
        &:last-child{
          border-bottom: 1px solid transparent;
        }
        &.active{
          border-bottom: 1px solid #1890FF;
          p{
            color: #1890FF;
          }
        }
        p{
          margin-bottom: 0;
        }
      }
    }
  }
  &.open{
    .box__backdrop{
      visibility: visible;
      opacity: 0.8;
    }
    .box__sort__product__content{
      transform: inherit;
      visibility: visible;
    }
  }
}
.fix__top{
  position: fixed;
  background: #FA8C16;
  top: 0;
  right: 0;
  left: 0;
  z-index: 3;
  .header__bell, .back{
    display: inline-block!important;
  }
}

.choose__product{
  .search__product__content{
    .share__near{
      background: #ffffff;
      .list__share__near{
        @media (min-width: 991px) {
          display: flex;
          flex-wrap: wrap;
          .share__near__item{
            width: calc(20% - 10px);
            margin-right: 10px;
            border: none;
            display: block;
            .btn_choose_blue{
              float: left;
            }
            .product__image{
              width: 115px;
              height: 115px;
              margin-right: 10px;
              margin-bottom: 10px;
              img{
                border-radius: 5px;
                object-fit: contain;
              }
            }
            .product__detail{
              width: calc(100%);
            }
          }
        }
      }
    }
    .home__product__content{
      @media (min-width: 991px) {
        .item__product{
          width: calc(100% / 6);
          .item__image{
            text-align: left;
          }
        }
      }
    }
  }
}

.filter__item{
  padding: 15px 0;
  .title{
    font-weight: 600;
    font-size: 14px;
  }
  .list__filter{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .ant-checkbox-wrapper + .ant-checkbox-wrapper{
      margin-left: 0;
    }
    .item__filter{
      width: calc(50% - 5px);
      margin-bottom: 10px;
      position: relative;
      .ant-btn:hover, .ant-btn:focus{
        color: #262626;
        border-color: #d9d9d9;
      }
      button{
        width: 100%;
        border-radius: 5px;
        padding: 0;
        label{
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 0;
        }
      }
      .icon_check, .check{
        position: absolute;
        right: 0;
        top: -2px;
        display: none;
      }
      .check{
        z-index: 13;
        top: 4px;
        right: 2px;
      }
      &.active{
        button{
          border-color: #1890FF!important;
        }
        .icon_check, .check{
          display: inline-block;
        }
      }
    }
  }
}
